export default {
  computed: {
    USDrate() {
      return this.checkIsUSC(this.accountCurrency) ? this.convertUSDToUSC(this.rate) : this.rate
    },
    USCMaxLimit() {
      return this.checkIsUSC(this.accountCurrency) ? this.convertUSDToUSC(this.maxLimit) : this.maxLimit
    },
  },
  methods: {
    initialRate(value) {
      return this.checkIsUSC(this.accountCurrency) ? this.convertUSCToUSD(value) : value
    },
    checkIsUSC(value) {
      return value === 'USC' ? true : false
    },
    convertUSCToUSD(value) {
      return (value * 10000) / 1000000
    },
    convertUSDToUSC(value) {
      return (value * 1000000) / 10000
    },
    resetAmount() {
      if (this[this.formName].amount !== 0) this.$refs[this.formName].validateField('amount')
    },
  },
}
