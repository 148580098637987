<template>
  <div>
    <el-form
      :model="withdrawForm"
      ref="withdrawForm"
      :rules="withdrawFormRules"
      class="top-left"
      :class="{ 'left-style': showRight }"
    >
      <el-form-item>
        <div class="title">
          <span>{{ $t('withdraw.default.accountDetail') }}</span>
        </div>
      </el-form-item>

      <!-- select account -->
      <div class="accSelect">
        <SelectForm
          v-model="withdrawForm.formAccount"
          name="formAccount"
          class="showPlaceholder"
          popperClass="withdraw-select"
        >
          <el-option
            v-for="item in formAccountOptions"
            :key="item.login"
            :value="JSON.stringify(item)"
            :label="getCurrencySymbol(item)"
            :data-testid="item.login"
          >
            <template v-if="item.login == tradingContestAccNo">
              <div>
                {{ getCurrencySymbol(item) }}
                <span class="ml-2"><TrophyIcon /></span>
              </div> </template
          ></el-option>
        </SelectForm>
      </div>

      <!-- account type -->
      <el-form-item class="labelTitle">
        <div>
          <label for="">{{ $t('common.field.accType') }}</label>
        </div>
        <div class="row">{{ mtAccountType }}</div>
      </el-form-item>

      <!-- amount input -->
      <div class="amount">
        <el-form-item :label="$t('withdraw.default.withdrawalAmount')" prop="amount">
          <numeric-input
            class="plain-input"
            ref="numberInput"
            v-model="withdrawForm.amount"
            :currency="withdrawForm.currency"
            :precision="2"
          ></numeric-input>
        </el-form-item>
      </div>

      <!-- continue button -->
      <div class="form-button continue-btn">
        <el-button class="btn-blue" @click="submit" data-testid="submit">
          {{ $t('common.button.continue') }}
        </el-button>
      </div>
    </el-form>
    <GeneralDialog :visible.sync="dialogVisible" visibleHeader visibleFooter hideChat>
      <div class="teal-dialog">
        <div class="text-center dialog-info" ref="dialogInfo"></div>
        <div class="dialog-footer text-center">
          <el-button class="btn-blue" @click="getApiBalances">
            <span class="me-3">{{ $t('common.button.ok') }}</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </div>
    </GeneralDialog>

    <CampaignWithdrawalModal
      :campaignWithdrawalModalVisible.sync="campaignWithdrawalModalVisible"
      @proceed="withdrawConfirmProceed"
    >
    </CampaignWithdrawalModal>

    <!-- <el-dialog
      class="withdraw_dialog"
      :title="$t('common.withdrawChannel.limitTitle')"
      :visible.sync="visible"
      width="500px"
      center
      :show-close="true"
    >
      <div style="text-align: center" class="word_break">
        {{
          fromFunctionCode == 'updatePassword'
            ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
            : fromFunctionCode == 'changeAuthenticator' ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
            : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
        }}
      </div>
      <div slot="footer" class="dialog-footer btn-box">
        <el-button style="width: 100%" @click="visible = false" class="btn-blue">{{
          $t('common.button.iKnow')
        }}</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import SelectForm from '@/components/form/Select';
import NumericInput from '@/components/NumericInput';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import CampaignWithdrawalModal from './CampaignWithdrawalModal.vue';
import splitThousands from '@/util/splitThousands';
import uscMixin from '@/mixins/usc';
import rounding from '@/util/rounding';
import {
  apiGetWithdrawalData_cp,
  apiCreditCardBalances,
  apiQueryRateUSDByTargetCurrency,
  apiGetWithdrawDeductCredit,
  apiNonCreditCardWithdrawTypesMinimumAmount,
  apiGetSecuritys,
  apiGetLBTWithdrawalData_cp
} from '@/resource';
import { decimalAdding } from '@/util/decimal';
import TrophyIcon from '../icon/Trophy.vue';
import { mapState } from 'vuex';
import { countryIsoCode } from '@/constants/countryCode';

export default {
  name: 'TopLeft',
  components: { NumericInput, SelectForm, GeneralDialog, CampaignWithdrawalModal, TrophyIcon },
  mixins: [uscMixin],
  data() {
    return {
      day: '',
      hour: '',
      cashLimitFlag: false,
      // visible: false,
      fromFunctionCode: '',

      deductCredit: 0,
      dialogVisible: false,
      creditCardBalances: [],
      lbtTypesAndBalancesData: [],
      formAccountOptions: [],
      countryBankList: [],
      userCountryCode: null,
      mtAccountType: null,
      withdrawForm: {
        formAccount: '',
        account: '',
        amount: 0,
        mtCategory: '',
        currency: null,
        balance: null
      },
      currencyRate: null,
      minlimit: this.$config.withdrawalDefaultAmountMin,
      otherMethodAmountMin: this.$config.withdrawalDefaultAmountMin,
      accountPaymentMinAmount: {},
      disabled: false,
      topForm: {},
      reset: true,
      showRight: false,
      campaignWithdrawalModalVisible: false,
      clickedProceed: false
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState('promotion/tradingContest', ['tradingContestAcc', 'tradingContestWithdrawRestrict']),
    withdrawFormRules() {
      return {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: this.validateAmount,
            trigger: 'blur'
          }
        ]
      };
    },
    totalCcBalance() {
      let total = 0;
      this.creditCardBalances.forEach(cc => {
        if (cc.creditCard.is_del !== 1 && !cc.isExpired && !cc.isPending) {
          total = decimalAdding(total, cc.balance, 2);
        }
      });
      return total;
    },
    otherMethodAmount() {
      return parseFloat(rounding(Math.round, this.withdrawForm.amount - this.totalCcBalance - this.lbtBalance, 2));
    },
    onlyShowCcAndLbt() {
      return (
        (this.showCC || this.showLBT) &&
        this.otherMethodAmount > 0 &&
        this.otherMethodAmount < this.otherMethodAmountMin
      );
    },
    showCC() {
      return this.creditCardBalances.length > 0;
    },
    showLBT() {
      return (
        this.withdrawForm.amount > this.totalCcBalance &&
        this.lbtBalance > 0 &&
        this.lbtTypesAndBalancesData.isLbtLimited
      );
    },
    showOtherPayment() {
      /**
       * Credit-Card-withdrawlal has no limit.
       * Non-Credit-Card-withdrawal limit to 40. (JPY, HKD need to equal to 40 USD.)
       */
      if (this.onlyShowCcAndLbt) {
        return false;
      }

      return this.withdrawForm.amount > decimalAdding(this.totalCcBalance, this.lbtBalance, 2);
    },
    dialogMessage() {
      return this.$DOMPurify.sanitize(
        this.$t('common.withdrawChannel.withdrawalMessage', {
          deductCredit: this.deductCredit,
          account: this.withdrawForm.account,
          currencyType: this.$options.filters.currencyToSymbol(this.withdrawForm.currency)
        }),
        { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] }
      );
    },
    tradingContestAccNo() {
      return this.tradingContestAcc ? this.tradingContestAcc : 'NA';
    },
    toShowCampaignWithdrawalModal() {
      if (
        this.tradingContestAccNo == this.withdrawForm.account &&
        this.clickedProceed == false &&
        this.tradingContestWithdrawRestrict == true
      ) {
        return true;
      }
      return false;
    },
    lbtBalance() {
      const hasProperty = this.lbtTypesAndBalancesData.lbtBalance.hasOwnProperty(this.withdrawForm.currency);
      const isLimited = this.lbtTypesAndBalancesData.isLbtLimited;
      if (hasProperty && isLimited) {
        return this.lbtTypesAndBalancesData.lbtBalance[this.withdrawForm.currency];
      } else {
        return 0;
      }
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.userCountryCode)).code;
    }
  },
  watch: {
    withdrawForm: {
      handler(form) {
        this.$emit('setForm', form);
        this.$emit('reset', true);
        this.reset = true;
      },
      deep: true
    },
    'withdrawForm.formAccount': {
      immediate: true,
      async handler(newValue, oldValue) {
        if (oldValue && newValue && oldValue !== newValue) {
          this.clickedProceed = false;
          await this.handleSetWithdrawForm(newValue, oldValue);
        }
      }
    },
    'withdrawForm.amount'(value) {
      this.disabled = false;
    },
    dialogVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.dialogInfo.innerHTML = this.dialogMessage;
        });
      } else {
        this.deductCredit = 0;
      }
    }
  },
  methods: {
    async handleSetWithdrawForm(data, oldData = null) {
      let oldDataParse = oldData ? JSON.parse(oldData) : null;
      const jsonParse = JSON.parse(data);
      this.withdrawForm.account = jsonParse.login;
      this.withdrawForm.balance = jsonParse.balance;
      this.withdrawForm.mtCategory = jsonParse.mtCategory;

      if (this.withdrawForm.currency === jsonParse.currency) {
        this.$refs.numberInput.updateCurrency(true);
      }

      // compare to previous chosen account
      // if min-Limitation or currency of the previous account has changed, call convertUSDToTargetCurrency
      if (
        oldDataParse &&
        (this.accountPaymentMinAmount[jsonParse.login].minAmount !==
          this.accountPaymentMinAmount[oldDataParse.login].minAmount ||
          jsonParse.currency !== oldDataParse.currency)
      ) {
        // get the payment's min limit amount of current account
        let minAmount =
          this.accountPaymentMinAmount[jsonParse.login].minAmount >= 0
            ? this.accountPaymentMinAmount[jsonParse.login].minAmount
            : this.$config.withdrawalDefaultAmountMin;
        await this.convertUSDToTargetCurrency(jsonParse.currency, minAmount);
      }

      this.withdrawForm.currency = jsonParse.currency;

      this.$refs['withdrawForm']?.fields.find(f => f.prop == 'amount').resetField();

      this.mtAccountType = jsonParse.mtAccountTypeDisplay;

      this.disabled = false;
    },
    convertUSDToTargetCurrency(currency, equalValue = this.$config.withdrawalDefaultAmountMin) {
      if (this.countryCode == 4759) {
        this.minlimit = equalValue;
        this.otherMethodAmountMin = equalValue;
        return;
      }
      const apiCurrencyList = this.$config.usdRateFromApiCurrencyList;
      if (!apiCurrencyList.includes(currency)) {
        this.minlimit = equalValue;
        this.otherMethodAmountMin = this.$config.withdrawalDefaultAmountMin;
        return;
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minlimit = equalValue * Number(res.data.data);
            this.otherMethodAmountMin = this.$config.withdrawalDefaultAmountMin * Number(res.data.data);
          } else {
            this.minlimit = equalValue;
            this.otherMethodAmountMin = this.$config.withdrawalDefaultAmountMin;
          }
        })
        .catch(error => {
          this.minlimit = equalValue;
          this.otherMethodAmountMin = this.$config.withdrawalDefaultAmountMin;
        });
    },
    async init() {
      try {
        await this.fetchTradingAccount();
        this.$root.$refs.topLeft = this;
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    async fetchTradingAccount() {
      try {
        const resp = await apiGetWithdrawalData_cp();
        if (resp.data.code === 0) {
          this.userCountryCode = parseInt(resp.data.data.countryCode);
          this.formAccountOptions = resp.data.data.logins;
          this.countryBankList = resp.data.data.withdrawBankList;
          if (this.formAccountOptions.length > 0) {
            this.withdrawForm.formAccount = JSON.stringify(this.formAccountOptions[0]);
            await this.handleSetWithdrawForm(JSON.stringify(this.formAccountOptions[0]));
          }

          await this.fetchAccountPaymentMinAmount();
        }
      } catch (error) {
        this.$message({
          message: this.$t('withdraw.default.fetchTradeAccFailed'),
          type: 'error'
        });
      }
    },
    async fetchAccountPaymentMinAmount() {
      const self_getMinWithdrawalTypesAmountLimit = data => {
        const minValue = data?.length
          ? data.reduce((prev, curr) => (prev.amountLimit < curr.amountLimit ? prev : curr))
          : null;

        return minValue && minValue.amountLimit !== data[0].amountLimit
          ? minValue
          : {
              id: 'all',
              amountLimit: this.countryCode == 4759 ? minValue.amountLimit : this.$config.withdrawalDefaultAmountMin
            };
      };

      try {
        const res = await apiNonCreditCardWithdrawTypesMinimumAmount();
        if (res?.status === 200 && res?.data) {
          const calData = Object.keys({ ...res?.data }).reduce((acc, item, index) => {
            const result = self_getMinWithdrawalTypesAmountLimit(res?.data[item]);
            acc[item] = {
              id: result.id,
              minAmount: result.amountLimit
            };
            return acc;
          }, {});
          const minAmount = calData[this.withdrawForm.account]?.minAmount;
          this.accountPaymentMinAmount = { ...calData };

          await this.convertUSDToTargetCurrency(this.withdrawForm.currency, minAmount);
        } else {
          await this.convertUSDToTargetCurrency(this.withdrawForm.currency, this.$config.withdrawalDefaultAmountMin);
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    // async getSecuritys() {
    //   let _this = this;
    //   await apiGetSecuritys().then(function (res) {
    //     if (res.data.code == 0 && res.data.data.length > 0) {
    //       let data = res.data.data;
    //       let limitObj = data.find(el => el.functionCode == 'withdraw');
    //       if (Object.keys(limitObj).length > 0) {
    //         _this.fromFunctionCode = limitObj.fromFunctionCode;
    //         _this.day = 'T + ' + limitObj.limitDayNumber;
    //         _this.hour = limitObj.lastHours;
    //         _this.visible = true;
    //       }
    //     }
    //   });
    // },
    withdrawConfirmProceed() {
      this.clickedProceed = true;
      this.submit();
    },
    async submit() {
      // await this.getSecuritys();
      // if (this.visible) return;
      if (this.toShowCampaignWithdrawalModal) {
        this.campaignWithdrawalModalVisible = true;
        return;
      }
      this.$refs['withdrawForm'].validate(async valid => {
        if (valid) {
          let accountDetail = this.formAccountOptions.find(item => item.login === parseInt(this.withdrawForm.account));
          let isCredit = accountDetail ? accountDetail.isCredit : false;

          if (isCredit) {
            let resDeductCredit = await apiGetWithdrawDeductCredit({
              sourceAccount: this.withdrawForm.account,
              withdrawAmount: this.withdrawForm.amount
            });

            if (resDeductCredit.data.code === 0 && resDeductCredit.data.data?.deductCredit) {
              this.deductCredit = resDeductCredit.data.data?.deductCredit;
              this.dialogVisible = true;
              return;
            }
          }

          this.getApiBalances();
        } else return false;
      });
    },
    validateForm() {
      return this.$refs['withdrawForm'].validate();
    },
    async checkMinLimit(selectedPayment) {
      // While user chooses second payment (none-credit-card methods) from TopRight component,
      // assign the specific payment method's minAmountLimit to minlimit. Limitations of each withdraw payments refer to data get from apiGetNonCreditCardWithdrawTypeCP.

      // here determine minAmount (consider with the apiNonCreditCardWithdrawTypesMinimumAmount api data)
      let currentMinAmount =
        Object.keys(this.accountPaymentMinAmount).length &&
        this.accountPaymentMinAmount[this.withdrawForm?.account]?.id !== 'all' &&
        this.accountPaymentMinAmount[this.withdrawForm?.account]?.id === selectedPayment.id
          ? this.accountPaymentMinAmount[this.withdrawForm.account].minAmount
          : selectedPayment.amountLimit;

      await this.convertUSDToTargetCurrency(this.withdrawForm.currency, currentMinAmount);

      this.emitTopForm(this.creditCardBalances);
      this.$refs['withdrawForm'].validate();
    },
    getApiBalances() {
      this.dialogVisible = false;

      Promise.all([
        apiCreditCardBalances({ currency: this.withdrawForm.currency }),
        apiGetLBTWithdrawalData_cp({ country: this.getCountryIsoCode })
      ])
        .then(resp => {
          this.creditCardBalances = resp[0].data;
          this.lbtTypesAndBalancesData = resp[1].data.data;
          this.showRight = true;

          this.emitTopForm();
          this.$emit('reset', false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    emitTopForm() {
      this.topForm = {
        creditCardBalances: this.creditCardBalances,
        showCC: this.showCC,
        showOtherPayment: this.showOtherPayment,
        totalCcBalance: this.totalCcBalance,
        userCountryCode: this.userCountryCode,
        onlyShowCcAndLbt: this.onlyShowCcAndLbt,
        lbtTypesAndBalancesData: this.lbtTypesAndBalancesData,
        lbtBalance: this.lbtBalance,
        showLBT: this.showLBT
      };
      this.$emit('setTopForm', this.topForm, this.countryBankList, this.showRight);
      this.disabled = this.showCC || this.showLBT || this.showOtherPayment;
    },
    getCurrencySymbol(item) {
      const accountType = this.$config.accountTypeMaps[Number(item.mtAccountType)] || null;
      return (
        item.login +
        ' - ' +
        accountType +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      );
    },
    resetWithdrawalData() {
      this.$emit('reset', true);
      this.getApiBalances();
    },
    validateAmount(rule, value, callback) {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) <= 0) {
        // CANADA ONLY
        if (this.withdrawForm.currency === 'CAD') {
          callback(
            new Error(
              this.$t('common.formValidation.amtCAD', {
                minLimit: this.minlimit,
                currency: 'CAD'
              })
            )
          );
        } else {
          callback(new Error(this.$t('common.formValidation.amt0')));
        }
      } else if (value < this.minlimit) {
        // CANADA ONLY
        if (this.withdrawForm.currency === 'CAD') {
          callback(
            new Error(
              this.$t('common.formValidation.amtCAD', {
                minLimit: this.minlimit,
                currency: 'CAD'
              })
            )
          );
        } else {
          callback(
            new Error(
              this.$t('common.formValidation.amtLarger', {
                minLimit: Math.ceil(this.minlimit),
                currency: this.withdrawForm.currency
              })
            )
          );
        }
      } else if (value > this.withdrawForm.balance) {
        callback(new Error(this.$t('withdraw.default.YourPaymentGreaterThanAvailableBalance')));
      } else callback();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/topLeft.scss';
@import '@/assets/css/components/withdraw/dialog';

.ml-2 {
  margin-left: 8px;
}

.teal-dialog {
  .dialog-info {
    margin-bottom: 36px;
    font-size: 22px;
    word-break: break-word;
    /deep/.count {
      color: $blue !important;
      white-space: nowrap;
    }
    /deep/.bold {
      font-weight: bold;
    }
  }
  .dialog-footer {
    margin-bottom: -45px;
  }
}
</style>
