<template>
  <div class="l-select" :value="$attrs.value" @click.capture="visible = true" :class="{ active: visible }">
    <!-- select tag -->
    <div class="tag" v-show="tagName">{{ tagName }}</div>
    <!-- select main -->
    <el-form-item :label="label" :prop="name">
      <el-select
        v-on="listeners"
        v-bind="$attrs"
        class="showPlaceholder"
        :class="{ selected: listeners, creditCard: name === 'creditCard', ccReset: reset }"
        :disabled="disabled || reset"
        :data-testid="testId || name"
        placeholder=""
        popper-class="withdraw-select"
        :value="testId"
      >
        <el-option v-for="item in withdrawalTypeList" :key="item.id" :value="item.id">
          <div class="select-options-container">
            <div class="select-text">{{ getTitleLabel(item.nameKey) }}</div>
            <img class="select_img type-Img" :src="withdrawalIMG(item.id)" alt />
          </div>
        </el-option>

        <template v-if="listeners" #prefix>
          <div v-if="chooseWithdrawalType">
            <img class="selected-img type-Img" :src="withdrawalIcon(chooseWithdrawalType.id)" alt />
            <div class="select-text">
              <p>{{ getTitleLabel(chooseWithdrawalType.nameKey) }}</p>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ '$' + amount }}
            </div>
          </div>
          <div v-else-if="showCreditCard">
            <img class="type-Img selected-img" src="@/assets/images/withdraw/master_card_icon.png" alt />
            <div class="select-text">
              {{ $t('common.withdrawChannel.creditcard') }}
              <el-popover
                class="popover"
                placement="right-end"
                trigger="hover"
                :content="$t('withdraw.default.remainingWithdraw')"
              >
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ '$' + amount }}
            </div>
          </div>
          <div v-else class="select-text">
            {{ selectText }}
          </div>
        </template>
      </el-select>
    </el-form-item>
    <el-dialog
      class="withdraw_dialog"
      :title="$t('common.withdrawChannel.limitTitle')"
      :visible.sync="limitVisible"
      width="500px"
      center
      :show-close="true"
    >
      <div style="text-align: center" class="word_break">
        {{
          fromFunctionCode == 'updatePassword'
            ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
            : fromFunctionCode == 'changeAuthenticator'
            ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
            : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
        }}
      </div>
      <div slot="footer" class="dialog-footer btn-box">
        <el-button style="width: 100%" @click="limitVisible = false" class="btn-blue">{{
          $t('common.button.iKnow')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import all withdraw types
import CreditCardWithdraw from './types/CreditCardWithdraw';
import BankTransfer from './types/BankTransfer';
import NetellerWithdraw from './types/NetellerWithdraw';
import FasapayWithdraw from './types/FasapayWithdraw';
import SkrillWithdraw from './types/SkrillWithdraw';
import ThaMalVieWithdraw from './types/ThaMalVieWithdraw';
import UnionpayWithdrawCps from './types/UnionpayWithdrawCps';
import BitcoinWithdraw from './types/BitcoinWithdraw';
import CryptoCpsWithdraw from './types/CryptoCpsWithdraw';
import UsdtWithdraw from './types/UsdtWithdraw';
import KoreaWithdraw from './types/KoreaWithdraw';
import BitwalletWithdraw from './types/BitwalletWithdraw';
import SticpayWithdraw from './types/SticpayWithdraw';
import IndonesianWithdraw from './types/IndonesianWithdraw';
import PhpAndSAWithdraw from './types/PhpAndSAWithdraw';
import AstropayWithdraw from './types/AstropayWithdraw';
import NigeriaWithdraw from './types/NigeriaWithdraw';
import IndiaWithdraw from './types/IndiaWithdraw';
import AfricaWithdraw from './types/AfricaWithdraw';
import PerfectMoneyWithdraw from './types/PerfectMoneyWithdraw';
import CanadaWithdraw from './types/CanadaWithdraw';
import LocalDepositorWithdraw from './types/LocalDepositorWithdraw';
import MexicoAndBrazilWithdraw from './types/MexicoAndBrazilWithdraw';
import CpsWithdraw from '@/components/cps/CpsWithdraw.vue';
import { mapState } from 'vuex';
import { statusConfig } from '../../constants/status';
import { withdrawListingIndex } from '@/constants/withdrawListingIndex';
import { apiGetSecuritys } from '@/resource';
export default {
  name: 'SelectWithdraw',
  props: {
    name: String,
    label: String,
    testId: String,
    availableTypes: Array,
    amount: Number,
    disabled: Boolean,
    reset: Boolean,
    cpsPayments: Array,
    showCreditCard: {
      type: Boolean,
      default: false
    },
    selectText: String,
    tagName: {
      type: String,
      default: ''
    },
    paymentAuthConfig: {
      type: Object,
      default: null
    }
  },
  components: {},
  data() {
    return {
      fromFunctionCode: '',
      day: '',
      hour: '',
      limitVisible: false,
      limitTypes: [],
      visible: false,
      withdrawalType: null,
      withdrawalTypeList: [],
      withdrawalTypesMap: {
        1: {
          component: CreditCardWithdraw,
          img: require('@/assets/images/withdraw/master_card_select.png'),
          icon: require('@/assets/images/withdraw/master_card_icon.png')
        },
        2: {
          component: BankTransfer,
          img: require('@/assets/images/withdraw/swift_select.png'),
          icon: require('@/assets/images/withdraw/swift_icon.png')
        },
        4: {
          component: UnionpayWithdrawCps,
          img: require('@/assets/images/withdraw/union_pay_select.png'),
          icon: require('@/assets/images/withdraw/union_pay_icon.png')
        },
        5: {
          component: CpsWithdraw, // Thailand IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        6: {
          component: CpsWithdraw, // Malaysia IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        7: {
          component: FasapayWithdraw,
          img: require('@/assets/images/withdraw/fasapay_select.png'),
          icon: require('@/assets/images/withdraw/fasapay_icon.png')
        },
        8: {
          component: CpsWithdraw, // Vietnam IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        9: {
          component: NigeriaWithdraw,
          img: require('@/assets/images/withdraw/nigeria_select.png'),
          icon: require('@/assets/images/withdraw/nigeria_icon.png')
        },
        24: {
          component: IndiaWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        31: {
          component: CpsWithdraw, // Skrill
          img: require('@/assets/images/withdraw/skrill_select.png'),
          icon: require('@/assets/images/withdraw/skrill_icon.png')
        },
        32: {
          component: CpsWithdraw, // Neteller
          img: require('@/assets/images/withdraw/neteller_select.png'),
          icon: require('@/assets/images/withdraw/neteller_icon.png')
        },
        34: {
          component: BitcoinWithdraw,
          img: require('@/assets/images/withdraw/bitcoin_select.png'),
          icon: require('@/assets/images/withdraw/bitcoin_icon.png')
        },
        35: {
          component: CpsWithdraw, // South Korea IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        36: {
          component: UsdtWithdraw,
          img: require('@/assets/images/withdraw/usdt_select.png'),
          icon: require('@/assets/images/withdraw/usdt_icon.png')
        },
        37: {
          component: BitwalletWithdraw,
          img: require('@/assets/images/withdraw/bitcoin_select.png'),
          icon: require('@/assets/images/withdraw/bitcoin_icon.png')
        },
        38: {
          component: SticpayWithdraw,
          img: require('@/assets/images/withdraw/sticpay_select.png'),
          icon: require('@/assets/images/withdraw/sticpay_icon.png')
        },
        39: {
          component: CpsWithdraw, // Indonesia IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        40: {
          component: CpsWithdraw, // Philippines Bank Transfer
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        // 41: {
        //   component: AstropayWithdraw,
        //   img: require('@/assets/images/withdraw/astropay_select.png'),
        //   icon: require('@/assets/images/withdraw/astropay_icon.png')
        // },
        42: {
          component: CpsWithdraw, // South Africa IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        50: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        51: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        52: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        53: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        54: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        55: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        56: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        57: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        58: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        60: {
          component: CanadaWithdraw,
          img: require('@/assets/images/withdraw/interac.png'),
          icon: require('@/assets/images/withdraw/interac_icon.png')
        },
        61: {
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/perfect_money_select.png'),
          icon: require('@/assets/images/withdraw/perfect_money_icon.png')
        },
        64: {
          component: CpsWithdraw, // Brazil IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        65: {
          component: LocalDepositorWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        67: {
          component: NigeriaWithdraw, // Nigeria IBT
          img: require('@/assets/images/withdraw/nigeria_select.png'),
          icon: require('@/assets/images/withdraw/nigeria_icon.png')
        },
        75: {
          component: CpsWithdraw, // Ebuy
          img: require('@/assets/images/withdraw/ebuy_select.png'),
          icon: require('@/assets/images/withdraw/ebuy_icon.png')
        },
        80: {
          component: CryptoCpsWithdraw,
          img: require('@/assets/images/withdraw/eth_select.png'),
          icon: require('@/assets/images/withdraw/eth_icon.png')
        },
        81: {
          component: CryptoCpsWithdraw,
          img: require('@/assets/images/withdraw/usdc_select.png'),
          icon: require('@/assets/images/withdraw/usdc_icon.png')
        },
        100: {
          component: CpsWithdraw, //PIX
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        }
      },
      withdrawalCPSTypesMap: {
        5: {
          // Thailand IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        6: {
          // Malaysia IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        7: {
          //Fasapay
          img: require('@/assets/images/withdraw/fasapay_select.png'),
          icon: require('@/assets/images/withdraw/fasapay_icon.png')
        },
        8: {
          // Vietnam IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        9: {
          //Nigeria
          img: require('@/assets/images/withdraw/nigeria_select.png'),
          icon: require('@/assets/images/withdraw/nigeria_icon.png')
        },
        24: {
          //India Bank Transfer
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        31: {
          // Skrill
          img: require('@/assets/images/withdraw/skrill_select.png'),
          icon: require('@/assets/images/withdraw/skrill_icon.png')
        },
        32: {
          // Neteller
          img: require('@/assets/images/withdraw/neteller_select.png'),
          icon: require('@/assets/images/withdraw/neteller_icon.png')
        },
        35: {
          // South Korea IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        37: {
          //Bitwallet
          img: require('@/assets/images/withdraw/bitcoin_select.png'),
          icon: require('@/assets/images/withdraw/bitcoin_icon.png')
        },
        38: {
          //Sticpay
          img: require('@/assets/images/withdraw/sticpay_select.png'),
          icon: require('@/assets/images/withdraw/sticpay_icon.png')
        },
        39: {
          // Indonesia IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        40: {
          // Philippines Bank Transfer
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        42: {
          // South Africa IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        50: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        51: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        54: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        56: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        57: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        58: {
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        61: {
          img: require('@/assets/images/withdraw/perfect_money_select.png'),
          icon: require('@/assets/images/withdraw/perfect_money_icon.png')
        },
        62: {
          //Japan
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_select.png')
        },
        63: {
          component: CpsWithdraw, // Mexico Bank Transfer
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_select.png')
        },
        64: {
          // Brazil IBT
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        67: {
          //Nigeria
          img: require('@/assets/images/withdraw/nigeria_select.png'),
          icon: require('@/assets/images/withdraw/nigeria_icon.png')
        },
        75: {
          // Ebuy
          img: require('@/assets/images/withdraw/ebuy_select.png'),
          icon: require('@/assets/images/withdraw/ebuy_icon.png')
        },
        99: {
          //Interac
          img: require('@/assets/images/withdraw/interac.png'),
          icon: require('@/assets/images/withdraw/interac_icon.png')
        },
        100: {
          //PIX
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        102: {
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        108: {
          //UAE-P2P-CPS
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        109: {
          //AitTm
          img: require('@/assets/images/withdraw/airtm_select.png'),
          icon: require('@/assets/images/withdraw/airtm_icon.png')
        },
        110: {
          // Euro Instant Bank Transfer
          component: CpsWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        }
      },
      chooseWithdrawalType: null,
      currentComponent: null,
      ngaCountryCode: 5796,
      withdrawLimitArrays: []
    };
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          if (this.isLimitedTypes(event)) {
            return (this.limitVisible = true);
          }

          if (this.cpsPayments.includes(event)) {
            this.currentComponent = CpsWithdraw;
          } else {
            this.currentComponent = this.withdrawalTypesMap[event].component;
          }

          this.chooseWithdrawalType = this.withdrawalTypeList.find(f => f.id == event);
          this.withdrawalType = event;
          this.emitWithdraw();
        }
      });
    },
    lang() {
      return this.$store.state.common.lang;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    regulator() {
      return this.$store.state.common.regulator;
    }
  },
  mounted() {
    this.getSecuritys();
  },
  watch: {
    reset(val) {
      if (!val) {
        this.emitWithdraw();
      } else {
        this.clearWithdrawData();
      }
    },
    availableTypes(val) {
      if (!val) return;

      let tmpList = [];
      const maps = { ...this.withdrawalTypesMap, ...this.withdrawalCPSTypesMap };
      Object.keys(maps).map(function (key) {
        tmpList.push(...val.filter(el => el.id == key));
      });

      // if IBT is not completed, hide bank transfer option
      // if (
      //   tmpList.length > 0 &&
      //   (this.ibtPoiAuditStatus !== statusConfig.completed || this.ibtPoaAuditStatus !== statusConfig.completed)
      // ) {
      //   tmpList = tmpList.filter(item => item.id !== 2);
      // }
      // sort payment channel
      tmpList = this.sortPaymentChannel(tmpList);

      this.withdrawalTypeList = tmpList;
    },
    withdrawalTypeList(val) {
      if (!val) return;
      if (!val.find(f => f.id == this.withdrawalType)) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    },
    disabled(val) {
      if (val) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    }
  },
  methods: {
    emitWithdraw() {
      this.$emit('setWithdraw', {
        currentComponent: this.currentComponent,
        chooseWithdrawalType: this.chooseWithdrawalType,
        withdrawalType: this.withdrawalType,
        amount: this.amount
      });
    },
    withdrawalIMG(id) {
      if (this.cpsPayments?.includes(id)) {
        return this.withdrawalCPSTypesMap[id].img;
      } else {
        //change nigeria bank transfer img
        if (id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode)
          return this.withdrawalTypesMap[9].img;
        return this.withdrawalTypesMap[id].img;
      }
    },
    withdrawalIcon(id) {
      if (this.cpsPayments.includes(id)) {
        return this.withdrawalCPSTypesMap[id].img;
      } else {
        //change nigeria bank transfer img
        if (id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode)
          return this.withdrawalTypesMap[9].icon;
        return this.withdrawalTypesMap[id].icon;
      }
    },
    isLimitedTypes(type) {
      const currLimitObj = this.withdrawLimitArrays.find(item => {
        let curr = item.limitWithdrawTypes ? item.limitWithdrawTypes.split(',').map(item => Number(item)) : [];
        return curr.includes(type);
      });

      if (currLimitObj && Object.keys(currLimitObj).length > 0) {
        this.fromFunctionCode = currLimitObj.fromFunctionCode;
        this.day = 'T + ' + currLimitObj.limitDayNumber;
        this.hour = currLimitObj.lastHours;
        this.limitTypes = currLimitObj.limitWithdrawTypes
          ? currLimitObj.limitWithdrawTypes.split(',').map(item => Number(item))
          : [];
        return true;
      } else {
        return false;
      }
      // return this.limitTypes.includes(type);
    },
    async getSecuritys() {
      let _this = this;
      await apiGetSecuritys().then(function (res) {
        if (res.data.code == 0 && res.data.data && res.data.data.length > 0) {
          let data = res.data.data;
          // 按时间排序
          _this.withdrawLimitArrays = data
            .filter(el => el.functionCode == 'withdraw')
            .sort((a, b) => b.limitTimeUTC - a.limitTimeUTC);
        }
      });
    },
    sortPaymentChannel(withdrawTypes) {
      let withdrawListOrder = [];
      let sortedWithdrawList = [];

      withdrawListingIndex.forEach(wi => {
        if (wi.id == this.countryCode) {
          withdrawListOrder = wi.order;
        }
      });

      if (withdrawListOrder.length == 0) {
        withdrawListingIndex.forEach(wi => {
          if (wi.id == 'global') {
            withdrawListOrder = wi.order;
          }
        });
      }

      withdrawListOrder.forEach(wi => {
        withdrawTypes.forEach(withdrawType => {
          if (wi == withdrawType.id) {
            sortedWithdrawList.push(withdrawType);
          }
        });
      });

      const remainingPayments = withdrawTypes.filter(v => !withdrawListOrder.includes(v.id));
      sortedWithdrawList = sortedWithdrawList.concat(remainingPayments);

      // IBT channel need to put in last order
      const ibtChannelIndex = sortedWithdrawList.findIndex(sw => sw.id === 2);
      const [ibtChannel] = sortedWithdrawList.splice(ibtChannelIndex, 1);
      sortedWithdrawList.push(ibtChannel);

      return sortedWithdrawList;
    },
    getTitleLabel(val) {
      if (this.$te(val) || this.$te(val, 'en_US')) {
        return this.$t(val);
      }
      return this.$options.filters.withdrawMethods(val);
    },
    clearWithdrawData() {
      this.$emit('setWithdraw', {
        currentComponent: null,
        chooseWithdrawalType: null,
        withdrawalType: null,
        withdrawalNote: null
      });
    },
    cancelChanges() {
      this.clearWithdrawData();
      this.withdrawalType = null;
      this.chooseWithdrawalType = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/selectWithdraw.scss';
</style>
