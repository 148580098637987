<template>
  <el-select
    :value="value"
    @change="$emit('input', $event)"
    :placeholder="$t('common.field.month')"
    :disabled="disabled"
    data-testid="month"
    :popper-class="popperClass"
  >
    <el-option
      v-for="item in monthOptions"
      :key="item"
      :selected="item == value"
      :value="item.toString()"
      :data-testid="item < 10 ? 'month0' + item : 'month' + item"
    ></el-option>
  </el-select>
</template>
<script>
import moment from 'moment';

export default {
  name: 'month',
  props: {
    value: [Number, String],
    year: [Number, String],
    disabled: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultMonthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      monthOptions: []
    };
  },
  mounted() {
    this.monthOptions = this.defaultMonthOptions;
  },
  watch: {
    year(year) {
      const oldLength = this.monthOptions.length;
      if (year == moment().year()) {
        this.monthOptions = [];
        let currentMonth = moment().month();
        while (currentMonth < 12) {
          this.monthOptions.push(currentMonth + 1);
          currentMonth++;
        }
      } else {
        this.monthOptions = this.defaultMonthOptions;
      }
      const presMonth = this.monthOptions.find(f => f == this.value);
      if (!presMonth) {
        this.$emit('input', '');
        this.$emit('fromDateChange');
      }
    }
  }
};
</script>
