<template>
  <div id="block">
    <div class="top">
      <img v-if="method == 'id'" src="@/assets/images/idPoa/id.png" />
      <img v-if="method == 'address'" src="@/assets/images/idPoa/poa.png" />
    </div>
    <div class="span" v-html="span"></div>
    <div class="middle red">
      <span
        v-if="customPendingReason || PendingReason"
        v-html="$t(`identityProof.reason`, { reason: customPendingReason ? customPendingReason : PendingReason })"
      >
      </span>
    </div>
    <div class="upload">
      <div v-if="keepUpload" class="red">
        {{ $t('register.confirmYourId.uploadTip') }}
      </div>
      <el-form label-position="top" status-icon>
        <vUpload :limit="limit" v-on:updateFileInfo="updateFileInfo" :selfText="true" :class="{ finish: showTick }">
          <div class="message" v-if="showTick">
            <div class="done"><img src="@/assets/images/dialog/dialog_true.png" alt="" /></div>
          </div>
          <div class="message" v-else>
            <div class="image"><img src="@/assets/images/register/upload.png" alt="" /></div>
            <div class="text">{{ $t('register.btn.upload') }}</div>
          </div>
        </vUpload>
      </el-form>
    </div>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload';

export default {
  name: 'block',
  data() {
    return {
      fileLength: 0
    };
  },
  props: {
    method: String,
    limit: Number,
    PendingReason: String,
    customPendingReason: String,
    keepUpload: Boolean
  },
  components: { vUpload },
  computed: {
    span() {
      switch (this.method) {
        case 'address':
          return this.$t('identityProof.popup.addressProofSpan');
        case 'id':
          return this.$t('identityProof.popup.idProofSpan');
      }
    },
    showTick() {
      return this.fileLength > 0;
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileLength = fileInfo.fileList.length;
      this.$emit('syncFile', fileInfo.fileList);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/home/uploadIdOrAddress/block.scss';
</style>
