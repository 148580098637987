<template>
  <!-- astropay -->
  <div id="astropayForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/astropay_icon.png" alt="astropay_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('withdraw.astropay.field.accountNumber')" prop="accountNumber">
              <el-input
                v-model="TransferForm.accountNumber"
                id="accountNumber"
                type="text"
                auto-complete="new-password"
                data-testid="accountNumber"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.phone')" prop="phoneNumber">
              <el-input
                v-model="TransferForm.phoneNumber"
                id="phoneNumber"
                auto-complete="new-password"
                data-testid="phoneNumber"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.importantNotes"
                id="importantNotes"
                auto-complete="new-password"
                data-testid="importantNotes"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validateNumber } from '@/util/validation';

export default {
  name: 'BitwalletWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  data() {
    return {
      TransferForm: {
        accountNumber: '',
        phoneNumber: '',
        importantNotes: ''
      },
      TransferFormRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.astropay.formValidation.accountNumberReq'),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.phone')
            }),
            trigger: 'blur'
          },
          {
            validator: validateNumber,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        accountNumber: this.TransferForm.accountNumber,
        phoneNumber: this.TransferForm.phoneNumber,
        importantNotes: this.TransferForm.importantNotes,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
