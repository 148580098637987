<template>
  <div>
    <div class="form-bottom-row clearfix">
      <div class="fr img-row">
        <img v-if="isSVGNga()" src="@/assets/images/withdraw/nigeria_icon.png" alt="nigeria_icon" />
        <img v-else src="@/assets/images/withdraw/swift_icon.png" alt="swift_icon" />
      </div>
      <div class="fl form_row top-bank-transfer">
        <el-form ref="TransferForm" :model="TransferForm" :rules="TransferFormRules">
          <ul class="form-list clearfix">
            <li class="fl">
              <SelectForm
                ref="selectedCardIDInput"
                v-model="TransferForm.selectedCardID"
                :label="$t('withdraw.default.selectBA')"
                name="selectedCardID"
                testId="selectedCardID"
                class="plain-select"
                popperClass="withdraw-select"
              >
                <el-option
                  v-for="item in availableCards"
                  :key="item.id"
                  :value="item.id"
                  :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                  :data-testid="`select_${item.id}`"
                ></el-option>
              </SelectForm>
            </li>
            <li class="fr" v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
              <!-- Transfer country select-->
              <WithdrawlSharedOptions
                ref="transferCountryStringInput"
                v-model="transferCountryString"
                :label="chooseWithdrawalType.subTypeNameKey"
                :list="chooseWithdrawalType.withdrawSubTypes"
                :disabled="transferCountryStringInputDisabled"
                class="plain-select"
              ></WithdrawlSharedOptions>
            </li>
          </ul>
        </el-form>
      </div>
    </div>
    <div>
      <component
        :is="currentComponent"
        ref="bankChild"
        :selectedCardInfo="selectedCardInfo"
        :transferCountryString="transferCountryString"
        :accountNumber="accountNumber"
        :amount="amount"
        :withdrawalType="withdrawalType"
        :userCountryCode="userCountryCode"
        :followerResults.sync="followerResults"
        :currency="currency"
      ></component>
    </div>
  </div>
</template>

<script>
import SelectForm from '@/components/form/Select';
import TransferIntWithdraw from './TransferIntWithdraw';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';

export default {
  name: 'BankTransfer',
  components: { SelectForm, TransferIntWithdraw, WithdrawlSharedOptions },
  props: [
    'accountNumber',
    'amount',
    'withdrawalType',
    'chooseWithdrawalType',
    'userCountryCode',
    'followerResults',
    'currency'
  ],
  mixins: [withdrawlMixin],
  data() {
    return {
      TransferForm: { selectedCardID: null, country_region: null },
      TransferFormRules: {
        selectedCardID: [
          {
            validator: (rule, value, callback) =>
              this.$refs.selectedCardIDInput
                ? this.TransferForm.selectedCardID !== null && this.TransferForm.selectedCardID !== undefined
                  ? callback()
                  : callback(new Error(this.$t('common.formValidation.selectBAReq')))
                : callback(),
            trigger: 'change'
          }
        ],
        country_region: [
          {
            validator: (rule, value, callback) =>
              // Have input and !disabled
              {
                this.$refs.transferCountryStringInput && !this.transferCountryStringInputDisabled
                  ? this.transferCountryString !== null && this.transferCountryString !== undefined
                    ? callback()
                    : callback(new Error(this.$t('common.formValidation.selectBAReq')))
                  : callback();
              },
            trigger: 'change'
          }
        ]
      },
      currentComponent: null,
      transferCountry: null,
      selectedCardInfo: null,
      transferCountryString: null,
      ngaCountryCode: 5796
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      if (this.availableCards.length !== 0) {
        this.selectedCardInfo = this.availableCards.find(item => item.id === value);
        this.transferCountry = this.selectedCardInfo.country;
        this.transferCountryString = this.transferCountry ? this.transferCountry.toString() : null;
      }
    },
    transferCountryString(value) {
      switch (value) {
        case '2':
          this.currentComponent = TransferIntWithdraw;
          break;
        default:
          this.currentComponent = null;
          break;
      }
    }
  },
  methods: {
    submitWithdraw() {
      return this.$refs.bankChild.submitWithdraw();
    },
    isSVGNga() {
      return this.regulator === 'svg' && this.userCountryCode == this.ngaCountryCode;
    }
  },
  computed: {
    regulator() {
      return this.$store.state.common.regulator;
    },
    transferCountryStringInputDisabled() {
      return this.TransferForm.selectedCardID !== -1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/common/SelectLine';
@media (min-width: 1200px) {
  .top-bank-transfer {
    .form-list {
      max-width: 66.6% !important;
    }
  }
}
</style>
