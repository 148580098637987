const UNIONPAY = 4
const THAILANDBANKTRANSFER = 5
const MALAYSIABANKTRANSFER = 6
const FASAPAY = 7
const VIETNAMBANKTRANSFER = 8
const NIGERIABANKTRANSFER = 9
const INDIABANKTRANSFER = 24
const SKRILL = 31
const NETELLER = 32
const SOUTHKOREABANKTRANSFER = 35
const BITWALLET = 37
const STICPAY = 38
const INDONESIABANKTRANSFER = 39
const PHILIPPINESBANKTRANSFER = 40
const SOUTHAFRICABANKTRANSFER = 42
const UGANDABANKTRANSFER = 50
const RWANDABANKTRANSFER = 51
const CAMEROONBANKTRANSFER = 54
const KENYABANKTRANSFER = 56
const GHANABANKTRANSFER = 57
const TANZANIABANKTRANSFER = 58
const PERFECTMONEY = 61
const JAPANBANKTRANSFER = 62
const MEXICOBANKTRANSFER = 63
const BRAZILBANKTRANSFER = 64
const NIGERIALOCALBANKTRANSFER = 67
const EBUY = 75
const INTERAC = 99
const PIX = 100
const TAIWANBANKTRANSFER = 102
const UAEBANKTRANSFER = 108
const AIRTM = 109
const EUBANKTRANSFER = 110

/** Channels that support more than 1 currency must be added to the MULTIPLE_CURRENCY_CHANNELS array
 * JPY : considered 1 currency, Do Not need to be added
 * USD, USC : considered 1 currency, Do Not need to be added
 * USD, JPY : considered More Than 1, Need to be added
 */
export const MULTIPLE_CURRENCY_CHANNELS = [SKRILL, NETELLER, PERFECTMONEY, BITWALLET, STICPAY]

export const CURRENCY_RESTRICTED_CHANNELS = [
  UNIONPAY,
  THAILANDBANKTRANSFER,
  MALAYSIABANKTRANSFER,
  FASAPAY,
  VIETNAMBANKTRANSFER,
  NIGERIALOCALBANKTRANSFER,
  INDIABANKTRANSFER,
  INDONESIABANKTRANSFER,
  PHILIPPINESBANKTRANSFER,
  SOUTHAFRICABANKTRANSFER,
  UGANDABANKTRANSFER,
  RWANDABANKTRANSFER,
  CAMEROONBANKTRANSFER,
  KENYABANKTRANSFER,
  GHANABANKTRANSFER,
  TANZANIABANKTRANSFER,
  PERFECTMONEY,
  SOUTHKOREABANKTRANSFER,
  SKRILL,
  NETELLER,
  EBUY,
  PIX,
  BRAZILBANKTRANSFER,
  BITWALLET,
  MEXICOBANKTRANSFER,
  JAPANBANKTRANSFER,
  STICPAY,
  NIGERIABANKTRANSFER,
  INTERAC,
  AIRTM,
  UAEBANKTRANSFER,
  EUBANKTRANSFER,
  TAIWANBANKTRANSFER,
]

export const CURRENCY_AVAILABLE_TYPES = {
  USD: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    NIGERIALOCALBANKTRANSFER,
    INDIABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    SOUTHAFRICABANKTRANSFER,
    UGANDABANKTRANSFER,
    RWANDABANKTRANSFER,
    CAMEROONBANKTRANSFER,
    KENYABANKTRANSFER,
    GHANABANKTRANSFER,
    TANZANIABANKTRANSFER,
    PERFECTMONEY,
    SOUTHKOREABANKTRANSFER,
    EBUY,
    SKRILL,
    NETELLER,
    PIX,
    BRAZILBANKTRANSFER,
    BITWALLET,
    MEXICOBANKTRANSFER,
    JAPANBANKTRANSFER,
    STICPAY,
    NIGERIABANKTRANSFER,
    AIRTM,
    UAEBANKTRANSFER,
    TAIWANBANKTRANSFER,
  ],
  USC: [
    UNIONPAY,
    THAILANDBANKTRANSFER,
    MALAYSIABANKTRANSFER,
    FASAPAY,
    VIETNAMBANKTRANSFER,
    NIGERIALOCALBANKTRANSFER,
    INDIABANKTRANSFER,
    INDONESIABANKTRANSFER,
    PHILIPPINESBANKTRANSFER,
    SOUTHAFRICABANKTRANSFER,
    UGANDABANKTRANSFER,
    RWANDABANKTRANSFER,
    CAMEROONBANKTRANSFER,
    KENYABANKTRANSFER,
    GHANABANKTRANSFER,
    TANZANIABANKTRANSFER,
    PERFECTMONEY,
    SOUTHKOREABANKTRANSFER,
    EBUY,
    SKRILL,
    NETELLER,
    PIX,
    BRAZILBANKTRANSFER,
    BITWALLET,
    MEXICOBANKTRANSFER,
    JAPANBANKTRANSFER,
    STICPAY,
    NIGERIABANKTRANSFER,
    AIRTM,
    UAEBANKTRANSFER,
    TAIWANBANKTRANSFER,
  ],
  EUR: [PERFECTMONEY, SKRILL, NETELLER, BITWALLET, STICPAY, EUBANKTRANSFER],
  CAD: [SKRILL, NETELLER, INTERAC, STICPAY],
  GBP: [SKRILL, NETELLER, STICPAY],
  AUD: [NETELLER, STICPAY, BITWALLET],
  KRW: [EBUY],
  BRL: [BRAZILBANKTRANSFER, PIX],
  JPY: [JAPANBANKTRANSFER, BITWALLET, STICPAY],
  SGD: [STICPAY],
  NZD: [STICPAY],
  HKD: [STICPAY],
}
