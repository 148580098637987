import { apiCheckInfoNoBonusCampaign, apiExchangeRate } from '@/resource'
import WithdrawlCheck from '@/components/promotion/common/WithdrawlCheck'

export default {
  components: {
    WithdrawlCheck,
  },
  data() {
    return {
      mt4AccountContent: {},
      promotionMinLimit: null,
      promotionMaxLimit: null,
      rate: null,
      noDepositCheckVisible: false,
      isAgreeTnc: false,
      campaignCompleted: false,
    }
  },
  mounted() {
    this.fetchNoBonusInfo()
  },
  computed: {
    isSelectedPromotionAccount() {
      return this.account === this.mt4AccountContent.mt4_account
    },
  },
  methods: {
    fetchNoBonusInfo() {
      apiCheckInfoNoBonusCampaign()
        .then(resp => {
          //In Campaign
          if (resp.data.data && resp.data.code === 0) {
            this.mt4AccountContent = resp.data.data.mt4AccountContent
            this.campaignCompleted = resp.data.data.completed
            if (!resp.data.data.openPosition && !this.campaignCompleted) {
              this.promotionMinLimit = resp.data.data.withdrawConstrain.min.amount
              this.promotionMaxLimit = resp.data.data.withdrawConstrain.max.amount
            }
          }
        })
        .catch(err => {
          this.$message({
            message: 'Fetch promotion information failed',
            type: 'error',
          })
        })
    },
    getApiReferralExchangeRate() {
      apiExchangeRate({ from: this.currentCurrency, to: 'USD' }).then(res => (this.rate = res.data.data))
    },
  },
}
