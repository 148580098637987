<template>
  <div id="withDraw">
    <!-- withdraw process -->
    <div class="content-box" v-show="formFlag">
      <div class="inner">
        <AuthenticationNote />
        <div class="contact-title">
          <h2>{{ $t('withdraw.title') }}</h2>
        </div>

        <!-- <PendingAction
          v-if="isPoiEditable || isPoaEditable"
          :type="idPass && isPoaEditable ? 'poaNotice' : 'identityProof'"
        />
        <Notification v-else-if="isPoiUnderReview || isPoaUnderReview" :id="isPoiUnderReview" :poa="isPoaUnderReview" /> -->

        <RegisterNotice page="withdraw" v-if="!isHideNotice" @hideNotice="hideNotice" />
      </div>

      <!-- main content -->
      <div v-if="showWithdraw" class="inner">
        <div class="withDraw-form">
          <div class="box box-card-top">
            <TopLeft
              ref="topLeft"
              :paymentAuthConfig="paymentAuthConfig"
              @setForm="getForm"
              @setTopForm="getTopForm"
              @reset="reset"
            ></TopLeft>
            <div v-if="showRight">
              <hr class="vl" />
            </div>
            <TopRight
              ref="topRight"
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :reset.sync="resetForm"
              :showRight="showRight"
              :disabled="disableOtherMeThod"
              :paymentAuthConfig="paymentAuthConfig"
              :disableOtherMeThod="disableOtherMeThod"
              :disableSecondMethod="disableSecondMethod"
              @setSecondWithdraw="getSecondWithdraw"
              @setWithdraw="getWithdraw"
            ></TopRight>
          </div>

          <div v-if="!resetForm">
            <CcMethod
              ref="ccMethod"
              v-if="topForm.showCC"
              @onDisableMethod="onDisableMethod"
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :isShowOtherPayment="topForm.showOtherPayment"
            ></CcMethod>

            <!--secondary Method -->
            <OtherMethod
              ref="secondMethod"
              v-if="(topForm.showLBT && chooseSecondWithdraw.chooseWithdrawalType) || disableMethod"
              :topForm="topForm"
              :withdrawForm="withdrawForm"
              :bankList="bankList"
              :disabled="disableSecondMethod"
              :chooseWithdraw="chooseSecondWithdraw"
              :withdrawalPayment="$store.state.cps.secondaryWithdrawalPayment"
            ></OtherMethod>

            <!--tertiary Method -->
            <OtherMethod
              ref="otherMethod"
              v-if="(topForm.showOtherPayment && chooseWithdraw.chooseWithdrawalType) || disableMethod"
              :topForm="topForm"
              :withdrawForm="withdrawForm"
              :bankList="bankList"
              :disabled="disableOtherMeThod"
              :chooseWithdraw="chooseWithdraw"
              :withdrawalPayment="$store.state.cps.withdrawalPayment"
            />
          </div>

          <!-- submit area -->
          <div class="box" v-if="showSubmit">
            <!-- <div class="box-card-verification">
              <VerificationCode ref="verificationCode" namespace="CP_WITHDRAW"></VerificationCode>
            </div> -->
            <div class="box-card-submit">
              <el-checkbox v-model="checkStatus" class="policy-checkbox" data-testid="checkbox">
                <i18n path="withdraw.default.agreePolicy">
                  <template v-slot:link>
                    <a @click.prevent="disclaimerVisible = true" data-testid="withdrawPolicy">
                      {{ $t('withdraw.default.withdrawPolicy') }}
                    </a>
                  </template>
                </i18n>
              </el-checkbox>
              <div>
                <el-button
                  class="btn-blue submit-btn"
                  :loading="loading"
                  :disabled="!checkStatus || loading"
                  @click="submitForm()"
                  data-testid="submit"
                >
                  {{ $t('withdraw.default.submitYourWithdrawal') }}
                </el-button>
              </div>
            </div>
          </div>

          <!-- notice area -->
          <InteractiveDialog :show.sync="positionDialog.show" :text="positionDialog.text">
            <template v-slot:btn>
              <div class="notice-actions" :class="{ 'one-btn': positionDialog.type !== 'Warning' }">
                <el-button
                  class="btn-blue"
                  v-if="positionDialog.showConfirm"
                  @click="equityConfirm(equityConfirmCallback)"
                  data-testid="confirm"
                >
                  {{ $t('common.button.confirm') }}
                </el-button>
                <el-button
                  class="btn-default"
                  v-if="positionDialog.showCancel"
                  @click="hidePositionDialog"
                  data-testid="cancel"
                >
                  {{ $t('common.button.cancel') }}
                </el-button>
              </div>
            </template>
          </InteractiveDialog>

          <!-- policy dialog -->
          <GeneralDialog :visible.sync="disclaimerVisible" visibleHeader hideChat :extraClass="'policy-dialog'">
            <div>
              <ul v-html="$t('withdraw.default.disclaimer', { platform: $config.info.fullName })"></ul>
            </div>
          </GeneralDialog>

          <!-- check bonus usage dialog -->
          <CheckDialog
            :isVisible.sync="tradingBonus.checkVisible"
            @onConfirm="submitWithdraw"
            :mainMessage="$t('promotion.tradingBonus.endedTip')"
          ></CheckDialog>
        </div>
      </div>
    </div>

    <!-- withdraw result -->
    <div class="result-wrapper" v-show="!formFlag">
      <div class="result-container">
        <Result v-if="successFlag" :fail="result.fail">
          <div>
            <div class="mb-2">{{ result.message }}</div>
          </div>
        </Result>
      </div>
    </div>

    <InfoDialog
      :visible.sync="showIBTValidate"
      :title="IBTvalidateDialog.title"
      :infoType="IBTvalidateDialog.iconType"
      :closeOnClickModal="false"
      @closCbDialog="closeInfoDialog"
    >
      <div>{{ IBTvalidateDialog.content }}</div>

      <template #actions>
        <Button buttonType="default" @click="goToFinancialVerification()">
          {{ IBTvalidateDialog.btnContent }}
        </Button>
        <Button
          v-if="IBTvalidateDialog.second_btn"
          buttonType="default"
          class="second_btn"
          textColor="#004ABC"
          bgColor="#fff"
          @click="closeInfoDialog"
        >
          {{ $t('verificationStatus.kyc.otherOption') }}
        </Button>
      </template>
    </InfoDialog>

    <!-- registration dialog (if registration is not finished) -->
    <InfoDialog
      :visible.sync="showRegistrationDialog"
      :title="$t('register.identityVerification.additionalInfo.title')"
      infoType="fail"
      :closeOnClickModal="false"
      @closCbDialog="closeRegisterDialog"
    >
      <div>{{ registerDialogContent }}</div>

      <template #actions>
        <Button buttonType="default" @click="goToRegistration(registerDestination)">
          {{ $t('verificationStatus.kyc.start') }}
        </Button>
      </template>
    </InfoDialog>
    <el-dialog
      class="withdraw_dialog"
      :title="$t('common.withdrawChannel.limitTitle')"
      :visible.sync="visible"
      width="500px"
      center
      :show-close="true"
    >
      <div style="text-align: center" class="word_break">
        {{
          fromFunctionCode == 'updatePassword'
            ? $t('common.withdrawChannel.limitPassword', { day: this.day, hour: this.hour })
            : fromFunctionCode == 'changeAuthenticator'
            ? $t('common.withdrawChannel.limitSecurityAuthenticator', { day: this.day, hour: this.hour })
            : $t('common.withdrawChannel.limitUserLogin', { day: this.day, hour: this.hour })
        }}
      </div>
      <div slot="footer" class="dialog-footer btn-box">
        <el-button style="width: 100%" @click="visible = false" class="btn-blue">{{
          $t('common.button.iKnow')
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible="confirmationVisibility"
      id="ConfirmationComponent"
      :close-on-click-modal="false"
      @close="cancelChanges"
    >
      <div class="dialog-body">
        <ConfirmationComponent
          :showDynamicComponent="dynamicParameters"
          @confirm="confirmChanges"
          @changeVisible="cancelChanges"
        ></ConfirmationComponent>
      </div>
    </el-dialog>
    <VerificationDialog
      ref="verification"
      :dialogVisible.sync="verificationVisibility"
      :usedAuthTypes="paymentAuthConfig?.authType"
      action="withdraw"
      @changeVisible="updateVerificationVisible"
      @confirm="confirmVerification"
    ></VerificationDialog>
    <CampaignWithdrawalModal
      :campaignWithdrawalModalVisible.sync="campaignWithdrawalModalVisible"
      @proceed="withdrawConfirmProceed"
      :isNotice="false"
    >
    </CampaignWithdrawalModal>
  </div>
</template>

<script>
// components
import NumericInput from '@/components/NumericInput';
import PendingAction from '@/components/home/PendingAction';
import Notification from '@/components/home/Notification';
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress';
import CcMethod from '@/components/withdraw/CcMethods';
import TopLeft from '@/components/withdraw/TopLeft';
import TopRight from '@/components/withdraw/TopRight';
import OtherMethod from '@/components/withdraw/OtherMethod';
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import VerificationCode from '@/components/withdraw/VerificationCode';
import Result from '@/components/Result';
import CheckDialog from '@/components/promotion/common/CheckDialog.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import CampaignWithdrawalModal from '@/components/withdraw/CampaignWithdrawalModal.vue';
import Button from '@/components/common/Button.vue';
import AuthenticationNote from '@/components/security/AuthenticationNote';

// mixins
import mixin from '@/mixins';
import idPoaMixin from '@/mixins/idPoa';
import checkPositionMixin from '@/mixins/page/tools/checkPosition';
import uscMixin from '@/mixins/usc';
/**
 *  'promotionNoDeopositMixin' need to add mixins if no deposit bonus re-online.
 */
import promotionNoDeopositMixin from '@/mixins/promotion/noDepositBonus';
import promotionTradingBonusMixin from '@/mixins/promotion/tradingBonus';
// utils
import splitThousands from '@/util/splitThousands';
import { statusConfig } from '@/constants/status';
// api
import { apiApplyWithdrawal_cp_batch, apiGetMultiFactorAuth, apiGetSecurityInfo } from '@/resource';
import { apiIbtGetData } from '@/resource/register';
import { mapState } from 'vuex';
import { generalRegisterDirectPage } from '@/util/register';
import RegisterNotice from '@/components/home/RegisterNotice.vue';
import { getStatus } from '@/util/register';
import store from '@/store';
import { mapVerifiedAuth, mapReverifiedBody } from '@/components/security/Security.js';
import ConfirmationComponent from '@/components/security/ConfirmationComponent.vue';
import VerificationDialog from '@/components/security/VerificationDialog.vue';

export default {
  name: 'WithdrawFunds',
  components: {
    NumericInput,
    PendingAction,
    Notification,
    UploadIdOrAddress,
    TopLeft,
    TopRight,
    OtherMethod,
    GeneralDialog,
    InteractiveDialog,
    Result,
    CcMethod,
    CheckDialog,
    VerificationCode,
    InfoDialog,
    Button,
    RegisterNotice,
    AuthenticationNote,
    ConfirmationComponent,
    VerificationDialog,
    CampaignWithdrawalModal
  },
  mixins: [mixin, idPoaMixin, checkPositionMixin, uscMixin, promotionNoDeopositMixin, promotionTradingBonusMixin],
  data() {
    return {
      fromFunctionCode: '',
      day: '',
      hour: '',
      visible: false,
      ibtPoiAuditStatus: '',
      ibtPoaAuditStatus: '',
      formFlag: true,
      withdrawForm: {
        formAccount: '',
        amount: 0,
        withdrawalType: ''
      },
      topForm: {
        creditCardBalances: [],
        showCC: false,
        showOtherPayment: false,
        totalCcBalance: 0,
        userCountryCode: ''
      },
      resetForm: true,
      showRight: false,
      lbtType: null,
      disableMethod: false,
      chooseSecondWithdraw: {},
      chooseWithdraw: {},
      otherType: null,
      bankList: {},
      checkStatus: true,
      disclaimerVisible: false,
      showWithdrawalDialog: false,
      accountInfo: null,
      successFlag: false,
      result: {
        message: this.$t('withdraw.default.success'),
        fail: false
      },
      AMLAlert: { units: 100, charge: 20 },
      showRegistrationDialog: false,
      showIBTValidate: false,
      IBTvalidateDialog: {
        title: '',
        content: '',
        iconType: '',
        isSecondBtn: false,
        btnContent: ''
      },
      registerDestination: null,
      registerDialogContent: null,
      registerClosDirection: 'register',
      isHideNotice: false,
      paymentAuthConfig: {},
      confirmationVisibility: false,
      dynamicParameters: null,
      verificationVisibility: false,
      apiRequest: null,
      campaignWithdrawalModalVisible: false,
      clickedProceed: false,
      loading: false
    };
  },
  computed: {
    ...mapState('register', ['currStep', 'poiAuditStatus', 'poaAuditStatus']),
    ...mapState('promotion/tradingContest', ['tradingContestAcc', 'tradingContestWithdrawRestrict']),
    showWithdraw() {
      return this.idPass && this.poaPass;
    },
    showSubmit() {
      const displayLBT = this.topForm.showLBT;
      const displayOtherPayment = this.topForm.showOtherPayment;

      const LBT_Pass = this.lbtType ? true : false;
      const OtherPayment_Pass = this.otherType ? true : false;

      if (this.resetForm) return false;

      if (displayLBT && !displayOtherPayment) {
        return LBT_Pass;
      } else if (!displayLBT && displayOtherPayment) {
        return OtherPayment_Pass;
      } else if (displayLBT && displayOtherPayment) {
        return LBT_Pass && OtherPayment_Pass;
      } else {
        return true;
      }
    },
    isInit() {
      return this.ibtPoiAuditStatus === statusConfig.init || this.ibtPoaAuditStatus === statusConfig.init;
    },
    isSubmitted() {
      return (
        (this.ibtPoiAuditStatus === statusConfig.submitted && this.ibtPoaAuditStatus === statusConfig.submitted) ||
        (this.ibtPoiAuditStatus === statusConfig.submitted && this.ibtPoaAuditStatus === statusConfig.reaudit) ||
        (this.ibtPoiAuditStatus === statusConfig.submitted && this.ibtPoaAuditStatus === statusConfig.completed) ||
        (this.ibtPoiAuditStatus === statusConfig.completed && this.ibtPoaAuditStatus === statusConfig.submitted) ||
        (this.ibtPoiAuditStatus === statusConfig.completed && this.ibtPoaAuditStatus === statusConfig.reaudit) ||
        (this.ibtPoiAuditStatus === statusConfig.reaudit && this.ibtPoaAuditStatus === statusConfig.submitted) ||
        (this.ibtPoiAuditStatus === statusConfig.reaudit && this.ibtPoaAuditStatus === statusConfig.reaudit) ||
        (this.ibtPoiAuditStatus === statusConfig.reaudit && this.ibtPoaAuditStatus === statusConfig.completed)
      );
    },
    isPendingOrReject() {
      return (
        this.ibtPoiAuditStatus === statusConfig.pending ||
        this.ibtPoaAuditStatus === statusConfig.pending ||
        this.ibtPoaAuditStatus === statusConfig.rejected ||
        this.ibtPoiAuditStatus === statusConfig.rejected
      );
    },
    isFinishIDProof() {
      return this.ibtPoiAuditStatus === statusConfig.pending || this.ibtPoiAuditStatus === statusConfig.rejected;
    },
    tradingContestAccNo() {
      return this.tradingContestAcc ? this.tradingContestAcc : 'NA';
    },
    toShowCampaignWithdrawalModal() {
      if (
        this.tradingContestAccNo == this.withdrawForm.account &&
        this.clickedProceed == false &&
        this.tradingContestWithdrawRestrict == true
      ) {
        return true;
      }
      return false;
    },
    disableOtherMeThod() {
      return this.disableMethod || (this.topForm.showLBT && this.lbtType === null);
    },
    disableSecondMethod() {
      return this.disableMethod;
    }
  },
  async mounted() {
    this.$store.commit('common/setAuthHeaders', {
      etxId: '',
      eCode: '',
      ptxId: '',
      pCode: '',
      tCode: ''
    });
    this.getLatestIBTStatus();
    await this.getAuthInfo();
    await this.getPaymentAuthConfig();
    // poi, poa (or IBT poi, poa) should all compelte to go withdrawal
    // if (this.poiAuditStatus !== statusConfig.completed || this.poaAuditStatus !== statusConfig.completed) {
    //   const destination = generalRegisterDirectPage({ isIBT: false });
    //   this.registerDestination = destination;
    //   this.registerDialogContent =
    //     destination === 'poaVerification' ||
    //     (this.poiAuditStatus === statusConfig.completed && this.poaAuditStatus !== statusConfig.completed)
    //       ? this.$t('register.poaVerification.additionalInfo.content')
    //       : this.$t('register.identityVerification.additionalInfo.content');
    //   this.showRegistrationDialog = true;
    // }
  },
  watch: {
    'topForm.showCC'() {
      this.$store.commit('common/setAuthHeaders', {
        etxId: '',
        eCode: '',
        ptxId: '',
        pCode: '',
        tCode: ''
      });
      if (this.topForm.showCC) {
        //ensure totp is verified
        if (
          this.paymentAuthConfig.paymentChannel?.includes(1) &&
          !this.paymentAuthConfig.authType.every(item => item.verified) &&
          !this.topForm.showOtherPayment
        ) {
          this.dynamicParameters = {
            title: this.$t('security.verificationTitle'),
            desc: this.$t('security.verificationDesc'),
            cancelButton: null,
            confirmButton: this.$t('common.button.confirm')
          };
          this.confirmationVisibility = true;
        }
      }
    },
    chooseWithdraw() {
      this.$store.commit('common/setAuthHeaders', {
        etxId: '',
        eCode: '',
        ptxId: '',
        pCode: '',
        tCode: ''
      });
      const usedId = this.chooseWithdraw?.chooseWithdrawalType?.id;
      if (
        this.paymentAuthConfig.paymentChannel?.includes(usedId) &&
        !this.paymentAuthConfig.authType.every(item => item.verified)
      ) {
        this.dynamicParameters = {
          title: this.$t('security.verificationTitle'),
          desc: this.$t('security.verificationDesc'),
          cancelButton: this.$t('common.button.cancel'),
          confirmButton: this.$t('common.button.confirm')
        };
        this.confirmationVisibility = true;
      }
    }
  },
  methods: {
    confirmChanges() {
      this.$router.push('/securityManagement');
    },
    cancelChanges() {
      this.confirmationVisibility = false;
      this.$refs.topRight.cancelChanges();
    },
    updateVerificationVisible() {
      this.verificationVisibility = false;
    },
    async getAuthInfo() {
      await apiGetSecurityInfo().then(resp => {
        if (resp.data.code == 0) {
          const usedValue = resp.data.data;
          this.$store.commit('common/setCheckedAuthStatus', usedValue.authStatus);
        }
      });
    },
    async getPaymentAuthConfig() {
      await apiGetMultiFactorAuth('withdraw')
        .then(resp => {
          if (resp.data.code == 0) {
            let usedRequiredAuths = this.$store.state.common.requiredAuths;
            usedRequiredAuths['withdraw'] = resp.data.data.authType;
            this.$store.commit('common/setRequiredAuths', usedRequiredAuths);
            this.paymentAuthConfig = {
              authType:
                resp.data.data.authType && resp.data.data.authType.length > 0
                  ? resp.data.data.authType.map(item => {
                      return {
                        type: item,
                        verified: mapVerifiedAuth(item, this.$store.state.common.checkedAuthStatus),
                        authenticated: false
                      };
                    })
                  : [],
              paymentChannel: resp.data.data.paymentChannel ? resp.data.data.paymentChannel : []
            };
          }
        })
        .catch(() => {
          console.log('getPaymentAuthConfig error');
        });
    },
    // fix:ACM-34977 安全验证通过且出金接口报错后 再次重复点击提交出金时安全验证确认按钮无法点击问题
    // 弹窗子组件内部usedObject是通过传入的paymentAuthConfig.authType中的authenticated过滤而来的 过滤后usedObject可能为undefined
    // 若需要再次进行安全验证 则需重置paymentAuthConfig.authType,将authenticated初始化为false
    repeatSubmitWhenSecurityAuthOpen() {
      if (this.paymentAuthConfig.authType && this.paymentAuthConfig.authType.length > 0) {
        this.paymentAuthConfig.authType = this.paymentAuthConfig.authType.map(item => {
          return {
            ...item,
            authenticated: false
          };
        });
      }
    },
    async getLatestIBTStatus() {
      const res = await apiIbtGetData();
      if (res.data.data && res.data.code === 0) {
        this.ibtPoaAuditStatus = getStatus(res.data.data.ibtPoaAuditStatus);
        this.ibtPoiAuditStatus = getStatus(res.data.data.ibtPoiAuditStatus);
        store.dispatch('register/actionChangeIbtPoaAuditStatus', this.ibtPoaAuditStatus);
        store.dispatch('register/actionChangeIbtPoiAuditStatus', this.ibtPoiAuditStatus);
      }
    },
    getTopForm(topForm, countryBankList, showRight) {
      this.topForm = { ...topForm };
      this.bankList = countryBankList;
      this.showRight = showRight;
    },
    getSecondWithdraw(val) {
      this.chooseSecondWithdraw = val;
      this.lbtType = val.withdrawalType;
      if (val.chooseWithdrawalType) {
        this.$refs.topLeft.checkMinLimit(val?.chooseWithdrawalType);
      }
    },
    getForm(form) {
      this.withdrawForm = { ...form };
    },
    reset(bool) {
      this.resetForm = bool;
      this.checkStatus = true;
      // this.disableOtherMeThod = false;
    },
    onDisableMethod(bool) {
      this.disableMethod = bool;
    },
    async getWithdraw(val) {
      await this.getLatestIBTStatus();
      if (val.withdrawalType === 2) {
        if (this.isInit) {
          this.showDialog(
            'verificationStatus.initInfo.title',
            'verificationStatus.initInfo.content',
            'fail',
            'verificationStatus.kyc.start',
            true
          );
          return;
        } else if (this.isSubmitted) {
          this.showDialog(
            'verificationStatus.submittedInfo.title',
            'verificationStatus.submittedInfo.content',
            'pending',
            'verificationStatus.kyc.otherOption',
            false
          );
          return;
        } else if (this.isPendingOrReject) {
          this.showDialog(
            'verificationStatus.pendingInfo.title',
            'verificationStatus.pendingInfo.content',
            'fail',
            'verificationStatus.kyc.upload',
            true
          );
          return;
        }
      }
      this.chooseWithdraw = val;
      this.otherType = val.withdrawalType;
      if (val.chooseWithdrawalType) {
        this.$refs.topLeft.checkMinLimit(val?.chooseWithdrawalType);
      }
    },
    showDialog(titleKey, contentKey, iconType, btnContentKey, second_btn) {
      this.IBTvalidateDialog = {
        title: this.$t(titleKey),
        content: this.$t(contentKey),
        iconType: iconType,
        btnContent: this.$t(btnContentKey),
        second_btn: second_btn
      };
      this.showIBTValidate = true;
    },
    getMethodComponent() {
      return [this.$refs.otherMethod, this.$refs.ccMethod, this.$refs.topLeft, this.$refs.secondMethod];
    },
    validComponent(otherMethodComponent, ccMethodComponent, topLeftComponent, secondMethodComponent) {
      return [
        otherMethodComponent ? otherMethodComponent.submitForm() : Promise.resolve(),
        ccMethodComponent ? ccMethodComponent.submitForm() : Promise.resolve(),
        topLeftComponent.validateForm(),
        secondMethodComponent ? secondMethodComponent.submitForm() : Promise.resolve()
      ];
    },
    submitForm() {
      // 父組件驗證
      const [getOtherMethodComponent, getCcMethodComponent, getTopLeftComponent, getSecondMethodComponent] =
        this.getMethodComponent();
      const [verifyOtherMethod, verifyCcMethod, verifyTopLeft, verifySecondMethod] = this.validComponent(
        getOtherMethodComponent,
        getCcMethodComponent,
        getTopLeftComponent,
        getSecondMethodComponent
      );
      let vaildParameter = getCcMethodComponent
        ? [verifyOtherMethod, ...verifyCcMethod, verifyTopLeft, verifySecondMethod]
        : [verifyOtherMethod, verifyCcMethod, verifyTopLeft, verifySecondMethod];

      Promise.all(vaildParameter).then(valid => {
        if (this.toShowCampaignWithdrawalModal) {
          this.campaignWithdrawalModalVisible = true;
          return;
        }
        if (valid) {
          this.checkPosition(
            this.withdrawForm.account,
            this.withdrawForm.amount,
            'withdraw',
            this.equityConfirmCallback
          );
        } else return false;
      });
    },
    checkNoDeoposit() {
      if (this.isSelectedPromotionAccount) {
        let ratedAmount =
          this.currentCurrency === 'USD' ? this.withdrawForm.amount : this.withdrawForm.amount * this.rate;
        let userWithdrawlConstrain = this.promotionMinLimit && this.promotionMaxLimit;
        if (userWithdrawlConstrain && this.promotionMinLimit <= ratedAmount <= this.promotionMaxLimit) {
          this.noDepositCheckVisible = true;
        } else {
          this.submitWithdraw();
        }
      } else {
        this.submitWithdraw();
      }
    },
    confirmVerification() {
      this.verificationVisibility = false;
      this.submitApi();
    },
    postApiApplyWithdrawal_cp() {
      // get data from CreditCard & OtherMethod components
      const [getOtherMethodComponent, getCcMethodComponent, getTopLeftComponent, getSecondMethodComponent] =
        this.getMethodComponent();

      let apiData = [];
      if (this.topForm.showCC) {
        const topFromData = { ...getCcMethodComponent.getFormDataOfChild() };
        if (topFromData.creditCardWithdrawalRequests.length > 0) {
          apiData.push({
            ...getCcMethodComponent.getFormDataOfChild()
          });
        }
      }
      if (this.topForm.showLBT) apiData.push({ ...getSecondMethodComponent.getFormDataOfChild() });
      if (this.topForm.showOtherPayment)
        apiData.push({
          ...getOtherMethodComponent.getFormDataOfChild()
        });
      return apiData;
    },
    submitWithdraw() {
      // main submit function

      //get request
      this.apiRequest = this.postApiApplyWithdrawal_cp();

      // do authentication first
      const needAuth = this.apiRequest.some(item =>
        this.paymentAuthConfig?.paymentChannel?.includes(item.withdrawalType)
      );
      if (needAuth && this.paymentAuthConfig.authType.length > 0) {
        this.verificationVisibility = true;
      } else {
        this.submitApi();
      }
    },
    submitApi() {
      this.loading = true;
      apiApplyWithdrawal_cp_batch(this.apiRequest, this.token)
        .then(async result => {
          this.repeatSubmitWhenSecurityAuthOpen(); // fix:出金报错后 再次点击提交出金时安全验证确认按钮无法点击问题
          const limitCodes = [637, 638, 639];
          if (limitCodes.includes(result.data.code)) {
            this.day = 'T +' + result.data.data.limitDayNumber;
            this.hour = result.data.data.lastHours;
            //638为修改账号引发的限制   639为修改密码引发的限制 637为修改2fa验证器引发的限制
            this.fromFunctionCode =
              result.data.code === 638
                ? 'updateUserLogin'
                : result.data.code === 637
                ? 'changeAuthenticator'
                : 'updatePassword';
            this.visible = true;
            // re-calling anti-reuse token
            await this.fetchToken()
              .then(resp => {
                this.loading = false;
              })
              .catch(resp => {
                this.loading = false;
                this.errorMessage(this.$t('resetPassword.failed'));
              });
            await this.getPaymentAuthConfig();
            return;
          } else if (result && (result.data.code === 0 || result.data.code === 300)) {
            this.loading = false;
            this.formFlag = false;
            this.successFlag = true;
            if (result.data.code === 300) {
              // for credit card fail
              this.result.fail = true;
              this.result.message = this.$t('withdraw.default.ccFail', {
                email: this.GLOBAL_CONTACT_EMAIL
              });
            }
          } else if (result.data.code === 1201 || result.data.code == 1202) {
            // re-calling anti-reuse token
            await this.fetchToken()
              .then(resp => {
                this.loading = false;
              })
              .catch(resp => {
                this.loading = false;
                this.errorMessage(this.$t('resetPassword.failed'));
              });
            await this.getPaymentAuthConfig();
            this.paymentAuthConfig.authType = mapReverifiedBody('withdraw', result);

            const allVerified = this.paymentAuthConfig.authType.every(item => item.verified == true);
            if (!allVerified) {
              if (
                this.paymentAuthConfig.paymentChannel.includes(1) &&
                this.apiRequest.filter(item => item.withdrawalType == 1).length > 0
              ) {
                this.dynamicParameters = {
                  title: this.$t('security.verificationTitle'),
                  desc: this.$t('security.verificationDesc'),
                  cancelButton: null,
                  confirmButton: this.$t('common.button.confirm')
                };
              } else {
                this.dynamicParameters = {
                  title: this.$t('security.verificationTitle'),
                  desc: this.$t('security.verificationDesc'),
                  cancelButton: this.$t('common.button.cancel'),
                  confirmButton: this.$t('common.button.confirm')
                };
              }
              this.confirmationVisibility = true;
            } else {
              this.verificationVisibility = true;
            }
          } else if (result.data.code === 1308) {
            return this.errorMessage(
              this.$t('promotion.noDepositBonus.errorMessageFor1308', {
                amount: result.data.data
              })
            );
          } else if (result.data.code === 1306) {
            if (result.data.data[1] == 'lots') {
              return this.errorMessage(
                this.$t('promotion.noDepositBonus.errorMessageFor1306lots', {
                  amount: result.data.data[0]
                })
              );
            } else {
              return this.errorMessage(
                this.$t('promotion.noDepositBonus.errorMessageFor1306trades', {
                  amount: result.data.data[0]
                })
              );
            }
          } else if (result.data.code === 1307) {
            return this.errorMessage(
              this.$t('promotion.noDepositBonus.errorMessageFor1307', {
                amount: result.data.data
              })
            );
          } else {
            this.errorMessage(this.$t('withdraw.default.failed'));
            // re-calling anti-reuse token
            this.fetchToken()
              .then(resp => {
                this.loading = false;
              })
              .catch(resp => {
                this.loading = false;
                this.errorMessage(this.$t('resetPassword.failed'));
              });
          }
        })
        .catch(() => {
          this.$message({
            message: this.$t('withdraw.default.failed'),
            type: 'error'
          });
          this.repeatSubmitWhenSecurityAuthOpen(); // fix:出金报错后 再次点击提交出金时安全验证确认按钮无法点击问题
        })
        .finally(() => {
          this.loading = false;
        });
    },
    equityConfirmCallback() {
      this.getActiveTradingBonusAccount().then(activeAccount => {
        if (`${activeAccount}` === `${this.withdrawForm.account}`) {
          this.tradingBonus.checkVisible = true;
        } else {
          this.submitWithdraw();
        }
      });
      /**
       * no deposit bonuse campaign temporarily removed.
       */
      //this.checkNoDeoposit()
    },
    goToRegistration(destination) {
      return destination ? this.$router.push({ name: destination }) : false;
    },
    closeInfoDialog() {
      this.showRegistrationDialog = false;
      window.location.reload();
    },
    goToFinancialVerification() {
      if (this.isInit) {
        return this.$router.push({ name: 'financialInfo', query: { entry: 'withdraw' } });
      } else if (this.isPendingOrReject) {
        if (!this.isFinishIDProof) {
          return this.$router.push({ name: 'addressProof', query: { entry: 'withdraw' } });
        }
        return this.$router.push({ name: 'iDProof', query: { entry: 'withdraw' } });
      }
      this.closeInfoDialog();
    },
    async closeRegisterDialog() {
      if (this.registerClosDirection) {
        await this.$router.push({ name: this.registerClosDirection }).catch(() => {});
      }
      this.showRegistrationDialog = false;
    },
    hideNotice(value) {
      this.isHideNotice = value;
    },
    withdrawConfirmProceed() {
      this.clickedProceed = true;
      this.submitForm();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdrawFunds.scss';
@import '@/assets/css/components/common/Input';
@import '@/assets/css/components/withdraw/dialog';

.mb-2 {
  margin-bottom: 8px;
}

.second_btn {
  margin-top: 16px;
}
/deep/ .verification-code-container {
  justify-content: start !important;
  .verification-code-item + .verification-code-item {
    margin-left: 36px;
  }
  .verification-code-item {
    .el-button {
      height: 39px;
      padding: 8px 16px;
    }
  }
}

#ConfirmationComponent {
  /deep/ .el-dialog {
    margin-top: 0vh !important;
    border-radius: 16px;
    padding: 40px 40px;
    height: auto;
    padding: 40px 50px;
    max-width: 480px;
    .el-dialog__body {
      text-align: center;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  /deep/ .el-dialog__header {
    padding: 0px;
  }

  /deep/ .el-dialog__body {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
    padding-bottom: 0px;
    .el-dialog__body {
      text-align: start;
    }

    .title {
      padding-bottom: 20px;
      color: black;
    }

    .body {
      padding-bottom: 20px;
    }
  }

  /deep/ .el-dialog__footer {
    padding: 0px;
  }
}
</style>
