<template>
  <el-form class="top-right">
    <el-form-item v-if="showRight">
      <div class="title">
        <span>{{ $t('withdraw.otherMethod.withdrawalMethod') }}</span>
      </div>
    </el-form-item>
    <!--credit Card Method-->
    <el-form-item v-if="topForm.showCC">
      <SelectWithdraw
        name="creditCard"
        :showCreditCard="true"
        :amount="creditCardAmount"
        :reset="reset"
        :disabled="true"
      ></SelectWithdraw>
    </el-form-item>

    <!--secondary Method options-->
    <el-form-item v-if="topForm.showLBT">
      <SelectWithdraw
        v-model="secondWithdrawalType"
        :value="secondWithdrawalType"
        name="secondWithdrawalType"
        key="secondWithdrawalType"
        :availableTypes="availableTypesOfSecondWithdrawal"
        :amount="secondWithdrawAmount"
        :reset="reset"
        :disabled="disableSecondMethod"
        :selectText="getSelectText('secondWithdraw')"
        :cpsPayments="cpsPayments"
        @setWithdraw="setSecondWithdraw"
      ></SelectWithdraw>
    </el-form-item>
    <!--Other Method options-->

    <el-form-item v-if="topForm.showOtherPayment">
      <SelectWithdraw
        ref="otherMethodSelect"
        v-model="withdrawalType"
        name="withdrawalType"
        :availableTypes="availableTypes"
        :paymentAuthConfig="paymentAuthConfig"
        :amount="withdrawAmount"
        :reset="reset"
        :disabled="disableOtherMeThod"
        :selectText="getSelectText('withdraw')"
        :cpsPayments="cpsPayments"
        @setWithdraw="setWithdraw"
      ></SelectWithdraw>
      <!-- <FeeTip
        :withdrawalType="withdrawalTypeId"
        :currency="withdrawForm.currency"
        :withdrawalAmount="withdrawAmount"
        :mt4Account="withdrawForm.account"
        :styleType="1"
      /> -->
    </el-form-item>
    <!-- <el-form-item v-if="showNote[withdrawalTypeId]">
      <div class="withdraw-note">
        {{ showNote[withdrawalTypeId].note }}
      </div>
    </el-form-item> -->

    <!-- withdrawal both credit card & none credit card message -->
    <el-form-item v-if="topForm.showCC && (topForm.showOtherPayment || topForm.showLBT)">
      <div class="withdraw-note">{{ $t('withdraw.default.withdrawNote') }}</div>
    </el-form-item>

    <!-- only withdrawal credit card message -->
    <el-form-item v-if="topForm.onlyShowCcAndLbt">
      <div class="withdraw-note">
        {{ $t('withdraw.default.onlyWithdrawalCcDesc') }}
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import SelectWithdraw from './SelectWithdraw';
import FeeTip from '@/components/withdraw/FeeTip';
import { apiGetNonCreditCardWithdrawTypeCP } from '@/resource';
import { apiGetCpsWithdrawalChannel } from '@/resource/cps';
import rounding from '@/util/rounding';
import splitThousands from '@/util/splitThousands';
import { countryIsoCode } from '@/constants/countryCode';
import {
  CURRENCY_RESTRICTED_CHANNELS,
  MULTIPLE_CURRENCY_CHANNELS,
  CURRENCY_AVAILABLE_TYPES
} from '@/constants/withdrawChannelCode';

export default {
  name: 'TopRight',
  components: { SelectWithdraw, FeeTip },
  props: {
    withdrawForm: Object,
    paymentAuthConfig: Object,
    topForm: Object,
    showRight: Boolean,
    reset: Boolean,
    disableOtherMeThod: {
      type: Boolean,
      default: false
    },
    disableSecondMethod: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      secondWithdrawalType: null,
      withdrawalType: null,
      secondAvailableTypes: [],
      availableTypes: [],
      withdrawData: {},
      ngaCountryCode: 5796,
      canCountryCode: 4575,
      ngaSupportCurrencies: ['USD', 'USC'],
      excludeCurrency: ['USD', 'USC'],
      // withdrawalTypeId: null,
      showNote: {
        60: {
          note: '®Trade-mark of Interac Corp. Used under license'
        }
      },
      cpsData: [],
      cpsPayments: []
    };
  },
  computed: {
    creditCardAmount() {
      return this.topForm.totalCcBalance >= this.withdrawData.amount
        ? this.withdrawData.amount
        : this.topForm.totalCcBalance;
    },
    secondWithdrawAmount() {
      let amt = parseFloat(rounding(Math.round, this.withdrawData.amount - this.topForm.totalCcBalance, 2));

      // 如果 amt 小於等於 0，返回 0，否則返回 lbtBalance 和 amt 中較小的值
      return amt <= 0 ? 0 : Math.min(amt, this.topForm.lbtBalance);
    },
    withdrawAmount() {
      if (this.secondWithdrawAmount === this.topForm.lbtBalance)
        return parseFloat(
          rounding(Math.round, this.withdrawData.amount - this.topForm.totalCcBalance - this.topForm.lbtBalance, 2)
        );
      else return 0;
    },
    ccWithdrawAmountWithCurrency() {
      return `${this.$options.filters.currencyToSymbol(this.withdrawData.currency)}${splitThousands(
        this.creditCardAmount,
        2
      )}`;
    },
    lbtWithdrawAmountWithCurrency() {
      return `${this.$options.filters.currencyToSymbol(this.withdrawData.currency)}${splitThousands(
        this.secondWithdrawAmount,
        2
      )}`;
    },
    regulator() {
      return this.$store.state.common.regulator;
    },
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code;
    },
    availableTypesOfSecondWithdrawal() {
      return this.availableTypes.filter(type => this.topForm.lbtTypesAndBalancesData.lbtType.includes(type.id));
    }
  },
  watch: {
    topForm: {
      handler() {
        if (!this.reset) {
          this.fetchWithdrawalMethod();
          this.withdrawData = { ...this.withdrawForm };
        }
      },
      deep: true
    }
  },
  methods: {
    fetchWithdrawalMethod() {
      apiGetNonCreditCardWithdrawTypeCP({ accountNumber: this.withdrawForm.account.toString() })
        .then(resp => {
          if (resp.status === 200) {
            // PU specific rule for filtering withdraw types
            let res = [];
            const totalWithdrawTypes = resp?.data || [];
            if (totalWithdrawTypes.length > 0) {
              res = totalWithdrawTypes;
              if (this.excludeWithdrawTypes()) res = res.filter(type => ![4, 9, 64, 65].includes(type.id));
              if (this.filterNgaWithdrawTypes()) res = res.filter(type => type.id !== 2);
              if (this.filterCanWithdrawTypes()) res = res.filter(type => type.id !== 60);
              if (this.filterIbtWithdrawTypes()) res = res.filter(type => type.id !== 2);

              // filter Perfect Money (id = 61)
              // if (this.filterPerfectMoneyType()) res = res.filter(type => type.id !== 61);
            }

            // get cps withdrawal type list
            this.fetchCpsWithdrawalMethod([...res]);
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(() => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    fetchCpsWithdrawalMethod(resData = []) {
      apiGetCpsWithdrawalChannel({ country: this.getCountryIsoCode })
        .then(resp => {
          if (resp.status === 200) {
            this.cpsData = [...resData];

            // combine channels into withdrawal type list
            resp.data.data.withdrawalType.channels.forEach(m => {
              let merchantVariable = JSON.parse(m.merchant_variable);
              let withdrawalList = {
                amountLimit: merchantVariable.amountLimit,
                availableCurrencies: null,
                category: 0,
                id: m.withdrawTypeId,
                cps: m,
                nameKey: merchantVariable.description_title
              };

              // restrict payment channel currencies
              this.restrictPaymentChannelCurrencies(withdrawalList);

              this.cpsPayments.push(m.withdrawTypeId);
            });

            // remove duplicates
            this.availableTypes = [...new Map([...resData, ...this.cpsData].map(m => [m.id, m])).values()];
            this.cpsPayments = [...new Set(this.cpsPayments)];
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
          }
        })
        .catch(err => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' });
        });
    },
    restrictPaymentChannelCurrencies(payment) {
      // check if payment channel is under restricted list
      if (CURRENCY_RESTRICTED_CHANNELS.includes(payment.id)) {
        if (
          CURRENCY_AVAILABLE_TYPES.hasOwnProperty(this.withdrawData.currency) &&
          CURRENCY_AVAILABLE_TYPES[this.withdrawData.currency].includes(payment.id)
        ) {
          if (MULTIPLE_CURRENCY_CHANNELS.includes(payment.id)) {
            if (payment.cps.currency_code == this.withdrawData.currency) {
              this.cpsData.push(payment);
            }
            if (payment.cps.currency_code === 'USD' && this.withdrawForm.currency === 'USC') {
              this.cpsData.push(payment);
            }
          } else {
            this.cpsData.push(payment);
          }
        }
      } else {
        this.cpsData.push(payment);
      }
    },
    cpsSelect(payment) {
      this.$store.dispatch('cps/actionSetWithdrawalPayment', payment);
    },
    secondaryCpsSelect(payment) {
      this.$store.dispatch('cps/actionSetSecondaryWithdrawalPayment', payment);
    },
    setWithdraw(val) {
      if (val.withdrawalType && this.cpsPayments.includes(val.withdrawalType)) {
        let selectedCps = this.availableTypes.find(w => w.id == val.withdrawalType);
        this.cpsSelect(selectedCps.cps);
      }
      // this.withdrawalTypeId = val.withdrawalType;
      this.$emit('setWithdraw', val);
    },
    async setSecondWithdraw(val) {
      if (val.withdrawalType && this.cpsPayments.includes(val.withdrawalType)) {
        let selectedCps = this.availableTypes.find(w => w.id == val.withdrawalType);
        this.secondaryCpsSelect(selectedCps.cps);
      }
      this.$emit('setSecondWithdraw', val);
    },
    getSelectText(parameter) {
      switch (parameter) {
        case 'secondWithdraw':
          return 'Local Bank Transfer';
        case 'withdraw':
          return this.$t('withdraw.default.addWithdrawalMethod');
        default:
          break;
      }
    },
    filterNgaWithdrawTypes() {
      return (
        this.regulator === 'svg' &&
        this.topForm.userCountryCode == this.ngaCountryCode &&
        !this.ngaSupportCurrencies.includes(this.withdrawData.currency)
      );
    },
    filterCanWithdrawTypes() {
      return this.topForm.userCountryCode == this.canCountryCode && this.withdrawData.currency !== 'CAD';
    },
    excludeWithdrawTypes() {
      return !this.excludeCurrency.includes(this.withdrawData.currency);
    },
    filterPerfectMoneyType() {
      const allowedCurrencies = ['USD', 'USC', 'EUR'];
      return this.withdrawForm?.currency ? !allowedCurrencies.includes(this.withdrawForm?.currency) : true;
    },
    filterIbtWithdrawTypes() {
      const restrictedCountries = [6777, 6907, 3701, 4575, 3899, 6581]; // Italy, United Kingdom, Belgium, Canada, Denmark, Spain
      const allowedCurrencies = {
        default: ['USC', 'USD', 'EUR', 'AUD', 'GBP', 'CAD'],
        spain: ['USD', 'EUR', 'AUD', 'GBP', 'CAD']
      };
      const isRestrictedCountry = restrictedCountries.includes(this.topForm.userCountryCode);
      const isSpain = this.topForm.userCountryCode === 6581; //Spain: 6581
      const allowedCurrency = isSpain ? allowedCurrencies.spain : allowedCurrencies.default;

      return this.regulator === 'svg' && isRestrictedCountry && !allowedCurrency.includes(this.withdrawData.currency);
    },
    cancelChanges() {
      this.$refs.otherMethodSelect.cancelChanges();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/topRight.scss';
</style>
