<template>
  <!-- FasaPay -->
  <div id="fasapayForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/fasapay_icon.png" alt="fasapay_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('withdraw.fasa.field.accName')" prop="fasapayAccountName_fasapay">
              <el-input
                v-model="TransferForm.fasapayAccountName_fasapay"
                id="fasapayAccountName_fasapay"
                auto-complete="new-password"
                data-testid="accountName"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.fasa.field.accNum')" prop="fasapayAccountNumber_fasapay">
              <el-input
                v-model="TransferForm.fasapayAccountNumber_fasapay"
                id="fasapayAccountNumber_fasapay"
                auto-complete="new-password"
                data-testid="accountNumber"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <ImportantNotesInput
                v-model="TransferForm.notes_fasapay"
                id="importantNotes"
                auto-complete="new-password"
                data-testid="importantNotes"
                class="plain-input"
              ></ImportantNotesInput>
            </el-form-item>
          </li>
        </ul>
        <FeeTip
          :withdrawalType="withdrawalType"
          :currency="currency"
          :withdrawalAmount="amount"
          :styleType="2"
          :mt4Account="accountNumber"
        />
      </el-form>
    </div>
  </div>
</template>

<script>
import FeeTip from '@/components/withdraw/FeeTip';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'FasapayWithdraw',
  components: { FeeTip, ImportantNotesInput },
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults', 'currency'],
  data() {
    return {
      TransferForm: {
        fasapayAccountName_fasapay: '',
        fasapayAccountNumber_fasapay: '',
        notes_fasapay: ''
      },
      TransferFormRules: {
        fasapayAccountName_fasapay: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNameReq'),
            trigger: 'blur'
          }
        ],
        fasapayAccountNumber_fasapay: [
          {
            required: true,
            message: this.$t('withdraw.fasa.formValidation.accNumReq'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        accountName: this.TransferForm.fasapayAccountName_fasapay,
        accountNumber: this.TransferForm.fasapayAccountNumber_fasapay,
        importantNotes: this.TransferForm.notes_fasapay,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  input {
    position: sticky;
    z-index: 101;
  }

  &.finish /deep/ label {
    font-size: 14px;
    bottom: 45px;
  }
}

.logo {
  height: 48px !important;
}

@media (min-width: 1200px) {
  .logo {
    height: 36px !important;
  }
}
</style>
