<template>
  <div class="box box-card-bottom">
    <el-form>
      <!-- head -->
      <el-row :gutter="40" justify="space-between" type="flex" class="head-wrapper">
        <el-col :xs="16" :sm="12">
          <div class="head-title">{{ $t('withdraw.otherMethod.manageWithdrawalDetails') }}</div>
        </el-col>
        <el-col :xs="8" :sm="12" class="total-amount"> {{ $t('common.field.amt') }}: ${{ amount }} </el-col>
      </el-row>

      <!-- alert -->
      <div v-if="disabled">
        <el-alert
          :closable="false"
          :description="$t('common.withdrawChannel.pendingArchiveRequest')"
          type="warning"
          show-icon
        >
        </el-alert>
      </div>

      <!-- withdraw methods -->
      <component
        :is="currentComponent"
        :accountNumber="withdrawForm.account"
        :amount="amount"
        :withdrawalType="withdrawalType"
        :chooseWithdrawalType="chooseWithdrawalType"
        :userCountryCode="topForm.userCountryCode"
        :followerResults.sync="followerResults"
        :bankList="bankList"
        :currency="withdrawForm.currency"
        :withdrawalPayment="withdrawalPayment"
        ref="child"
      ></component>
    </el-form>
  </div>
</template>

<script>
import rounding from '@/util/rounding';

export default {
  name: 'otherMethod',
  props: {
    withdrawForm: Object,
    topForm: Object,
    bankList: Object,
    followerResults: Array,
    chooseWithdraw: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    withdrawalPayment: Object
  },
  data() {
    return {
      withdrawalType: null,
      availableTypes: [],
      currentComponent: null,
      chooseWithdrawalType: {}
    };
  },
  mounted() {
    this.setChooseWithdraw(this.chooseWithdraw);
  },
  watch: {
    chooseWithdraw(val) {
      this.setChooseWithdraw(val);
    }
  },
  computed: {
    amount() {
      return this.chooseWithdraw.amount;
    }
  },
  methods: {
    valideWithdrawForm() {
      this.$emit('valideWithdrawForm');
    },
    setChooseWithdraw(val) {
      this.currentComponent = val.currentComponent;
      this.chooseWithdrawalType = val.chooseWithdrawalType;
      this.withdrawalType = val.withdrawalType;
    },
    getFormOfChild() {
      // get form of child
      return this.withdrawalType === 2
        ? this.$refs.child?.$refs?.bankChild?.$refs?.['TransferForm'] ?? this.$refs.child?.$refs?.['TransferForm']
        : this.$refs.child.$refs['TransferForm'];
    },
    getFormDataOfChild() {
      // get data of child for apply withdrawl API
      return this.withdrawalType === 2
        ? this.$refs.child.$refs.bankChild.getFormData()
        : this.$refs.child.getFormData();
    },
    submitForm() {
      // 子組件驗證
      return this.getFormOfChild() ? this.getFormOfChild().validate() : Promise.reject();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/otherMethod.scss';
</style>
<style lang="scss">
.withDraw-form .text_red {
  color: red;
  font-weight: bold;
  padding-bottom: 2rem;
}
</style>
