<template>
  <div id="sticpayForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/interac_icon.png" alt="interac_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list single-field clearfix">
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
              <ImportantNotesInput
                v-model="TransferForm.notes"
                id="notes"
                data-testid="importantNotes"
                class="plain-input"
              ></ImportantNotesInput>
            </el-form-item>
          </li>
        </ul>
      </el-form>
      <p class="mb-2 text_red">
        {{ $t('withdraw.LBTchannelsNote') }}
      </p>
    </div>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'CanadaWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array
  },
  components: { ImportantNotesInput },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      TransferForm: {
        accountNumber: '',
        notes: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAccNumReq'),
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);

      this.TransferForm = {
        selectedCardID: value,
        accountNumber: selectedCardInfo.accountNumber || '',
        notes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };
      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        importantNotes: this.TransferForm.notes,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
