<template>
  <!-- Nigeria-->
  <div id="nigeriaForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img src="@/assets/images/withdraw/nigeria_icon.png" alt="vietna_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="NigeriaFormRules">
        <ul class="form-list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="numberSelection"
              testId="selectedCardID"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form-list clearfix">
            <li>
              <SelectForm
                v-model="TransferForm.bankName"
                class="plain-select"
                :label="$t('common.field.bankName')"
                name="bankName"
                data-testid="bankName"
                popperClass="withdraw-select"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="(item, index) in bankList"
                  :key="index"
                  :value="item"
                  :label="index"
                  :data-testid="item"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <el-form-item :label="$t('common.field.accNum')" prop="accountNumber">
                <el-input
                  v-model="TransferForm.accountNumber"
                  id="accountNumber"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="accountNumber"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="beneficiaryName">
                <el-input
                  v-model="TransferForm.beneficiaryName"
                  id="beneficiaryName"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="beneficiaryName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                <ImportantNotesInput
                  v-model="TransferForm.notes"
                  id="notes"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="importantNotes"
                ></ImportantNotesInput>
              </el-form-item>
            </li>
            <li>
              <el-form-item class="data" prop="rate">
                <p>
                  {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'NGN' }) }}
                  <span> {{ rate }}</span>
                </p>
              </el-form-item>
            </li>
            <li>
              <div v-if="!isdisabled">
                <el-form-item class="upload" prop="uploadedFile">
                  <div class="labelTitle">
                    <span class="required_icon">*</span>
                    <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
                  </div>
                  <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
                </el-form-item>
              </div>
            </li>
          </ul>

          <p class="mb-2 text_red">
            {{ $t('withdraw.LBTchannelsNote') }}
          </p>

          <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
              {{ $t('withdraw.default.remember') }}
            </el-checkbox>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { apiQueryRate_USD_NGN } from '@/resource';
import SelectForm from '@/components/form/Select';
import vUpload from '@/components/withdraw/withdrawUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'NigeriaWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'bankList', 'followerResults'],
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { SelectForm, vUpload, ImportantNotesInput },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      accountCurrency: 'USD',
      rate: 0,
      form: {
        depositCurrency: 'NGN'
      },
      minLimit: 40,
      TransferForm: {
        bankName: '',
        accountNumber: '',
        beneficiaryName: '',
        notes: '',
        userPaymentInfoId: null
      },
      NigeriaFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.bankBeneficiaryName')
            }),
            trigger: ['blur', 'change']
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: []
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      console.log(selectedCardInfo);
      this.TransferForm = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        beneficiaryName: selectedCardInfo.beneficiaryName || '',
        importantNotes: selectedCardInfo.notes || '',
        userPaymentInfoId: isCurrentCard ? selectedCardInfo.id : null
      };
      this.isdisabled = isCurrentCard ? true : false;
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['TransferForm'].validateField('uploadedFile');
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName,
        accountNumber: this.TransferForm.accountNumber,
        beneficiaryName: this.TransferForm.beneficiaryName,
        importantNotes: this.TransferForm.notes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults,
        fileList: this.fileList
      };
    },
    queryRate() {
      apiQueryRate_USD_NGN()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'INR' })
          );
        });
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/common/SelectLine';
</style>
