<template>
  <div class="promotion">
    <DialogVersion3 :visible.sync="visible" visibleFooter hideChat>
      <div class="top">
        <div class="header-text">{{ $t('promotion.header') }}</div>
        <ol v-html="$t('promotion.tnc2')"></ol>
      </div>
      <div class="tnc">
        <el-form :model="form" :rules="rule" :show-message="false" ref="Form">
          <el-form-item prop="checked">
            <el-checkbox v-model="form.checked" data-testid="tncConfirm">
              <i18n path="openAdditAcc.byTicking" tag="div">
                <template v-slot:tnc>{{ $t('openAdditAcc.tnc') }} </template>
              </i18n>
            </el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-box">
        <div class="btn-box">
          <el-button
            class="btn-default"
            @click="agreeAction"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="agreeAction"
          >
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button
            class="btn-blue"
            @click="disableNoDepositCheckVisible(false)"
            :class="{ 'long-text-btn': ['pt', 'es'].includes(lang) }"
            data-testid="disagreeAction"
          >
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import { checkAgreedTNC } from '@/util/validation';

export default {
  name: 'WithdrawlCheck',
  props: {
    noDepositCheckVisible: Boolean
  },
  components: { DialogVersion3 },
  data() {
    return {
      form: {
        checked: false
      },
      visible: false,
      rule: {
        checked: [{ validator: checkAgreedTNC, trigger: 'change' }]
      }
    };
  },
  watch: {
    noDepositCheckVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableNoDepositCheckVisible(bool);
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  methods: {
    disableNoDepositCheckVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:noDepositCheckVisible', bool);
      });
    },
    agreeAction() {
      this.$refs['Form'].validate(valid => {
        if (valid) {
          this.$emit('submitWithdraw');
          this.disableNoDepositCheckVisible(false);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/promotion.scss';
</style>
