<template>
  <!-- PaygateIndiaInstantWithdraw -->
  <div id="PaygateIndiaInstantWithdraw" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/vietna_icon.png" alt="vietna_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="selectedCardID"
              data-testid="selectedCardID"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form-list clearfix">
            <li>
              <el-form-item :label="$t('common.field.bankName')" prop="bankName_india">
                <el-input
                  v-model="TransferForm.bankName_india"
                  id="bankName_india"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress_india">
                <el-input
                  v-model="TransferForm.bankAddress_india"
                  id="bankAddress_india"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankAddress"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="bankBeneficiaryName_india">
                <el-input
                  v-model="TransferForm.bankBeneficiaryName_india"
                  id="bankBeneficiaryName_india"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="beneficiaryName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
                <el-input
                  v-model.trim="TransferForm.accountNumber"
                  id="accountNumber"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="accountNumber"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankBranch')" prop="bankBranchName_india">
                <el-input
                  v-model="TransferForm.bankBranchName_india"
                  id="bankBranchName_india"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="bankBranchName"
                  :disabled="isdisabled"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.ifscCode')" prop="ifscCode_india">
                <el-input
                  v-model="TransferForm.ifscCode_india"
                  id="ifscCode_india"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="ifscCode"
                  :disabled="isdisabled"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.email')" prop="email">
                <el-input
                  v-model="TransferForm.email"
                  id="email"
                  type="email"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="email"
                  :disabled="isdisabled"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.phone')" prop="phoneNumber">
                <el-input
                  v-model="TransferForm.phoneNumber"
                  id="phoneNumber"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="phoneNumber"
                  :disabled="isdisabled"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.imptNotes')" prop="notes_india">
                <ImportantNotesInput
                  v-model="TransferForm.notes_india"
                  id="notes_india"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="importantNotes"
                ></ImportantNotesInput>
              </el-form-item>
            </li>
          </ul>

          <p class="mb-2 text_red">
            {{ $t('withdraw.LBTchannelsNote') }}
          </p>

          <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
              {{ $t('withdraw.default.remember') }}
            </el-checkbox>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import { validateEmail } from '@/util/validation';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import SelectForm from '@/components/form/Select';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'PaygateIndiaInstantWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array
  },
  components: { SelectForm, ImportantNotesInput },
  mixins: [withdrawlMixin, bankTransferMixin],
  data() {
    return {
      TransferForm: {
        bankName_india: '',
        bankAddress_india: '',
        bankBeneficiaryName_india: '',
        accountNumber: '',
        notes_india: '',
        ifscCode_india: '',
        email: '',
        phoneNumber: '',
        bankBranchName_india: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName_india: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAddress_india: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBeneficiaryName_india: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        ifscCode_india: [
          {
            required: true,
            message: this.$t('common.formValidation.ifscCodeReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBranchName_india: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: ['blur', 'change']
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('withdraw.transfer.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('common.field.phone')
            }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);

      this.TransferForm = {
        selectedCardID: value,
        bankName_india: selectedCardInfo.bankName || '',
        bankBeneficiaryName_india: selectedCardInfo.beneficiaryName || '',
        bankAddress_india: selectedCardInfo.bankAddress || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        email: selectedCardInfo.email || '',
        phoneNumber: selectedCardInfo.phoneNumber || '',
        notes_india: selectedCardInfo.importantNotes || '',
        ifscCode_india: selectedCardInfo.ifscCode || '',
        bankBranchName_india: selectedCardInfo.bankBranchName || '',
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };
      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName_india'(value) {
      this.TransferForm.bankBeneficiaryName_india = this.latinNumberValidator(value);
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName_india,
        bankAddress: this.TransferForm.bankAddress_india,
        beneficiaryName: this.TransferForm.bankBeneficiaryName_india,
        accountNumber: this.TransferForm.accountNumber,
        email: this.TransferForm.email,
        phoneNumber: this.TransferForm.phoneNumber,
        importantNotes: this.TransferForm.notes_india,
        ifscCode: this.TransferForm.ifscCode_india,
        bankBranchName: this.TransferForm.bankBranchName_india,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
@import '@/assets/css/components/common/SelectLine';
</style>
