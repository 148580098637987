<template>
  <el-select 
    :value="value" 
    @change="$emit('input', $event)"
    :placeholder="$t('common.field.year')"
    :disabled="disabled"
    data-testid="year"
    :popper-class="popperClass"
  >
    <el-option
      v-for="item in yearOptions"
      :key="item.value"
      :selected="item == value"
      :value="item"
      :data-testid="item"
    ></el-option>
  </el-select>
</template>
<script>
import moment from 'moment';

export default {
  name: 'year',
  props: {
    value: [Number, String],
    disabled: {
      type: Boolean,
      default: false
    },
    popperClass: {
      type: String,
      default: "",
    }, 
  },
  data() {
    return {
      yearOptions: []
    };
  },
  mounted() {
    this.generateYearOptions();
  },
  methods: {
    generateYearOptions: function() {
      this.yearOptions = [];
      let i = 0;
      let temp = moment().year();
      for (i; i < 10; i++) {
        this.yearOptions.push(temp.toString());
        temp++;
      }
    }
  }
};
</script>
