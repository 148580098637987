<template>
  <div id="perfectMoneyForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img src="@/assets/images/withdraw/perfect_money_icon.png" alt="perfect_money_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list two-fields clearfix">
          <li>
            <el-form-item :label="$t('withdraw.perfectMoney.field.email')" prop="perfectMoneyEmail">
              <el-input
                v-model="TransferForm.perfectMoneyEmail"
                id="perfectMoneyEmail"
                type="email"
                auto-complete="new-password"
                data-testid="netellerEmail"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.perfectMoney.field.account')" prop="perfectMoneyAccount">
              <el-input
                v-model="TransferForm.perfectMoneyAccount"
                id="perfectMoneyAccount"
                auto-complete="new-password"
                data-testid="perfectMoneyAccount"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.importantNotes"
                id="importantNotes"
                auto-complete="new-password"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import { validateEmail, validateAlphaAndNumeric } from '@/util/validation';

export default {
  name: 'PerfectMoneyWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  data() {
    return {
      TransferForm: {
        perfectMoneyEmail: '',
        perfectMoneyAccount: '',
        importantNotes: ''
      },
      TransferFormRules: {
        perfectMoneyEmail: [
          {
            required: true,
            message: this.$t('withdraw.perfectMoney.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ],
        perfectMoneyAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('withdraw.perfectMoney.field.account')
            }),
            trigger: 'blur'
          },
          {
            validator: validateAlphaAndNumeric,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        perfectMoneyEmail: this.TransferForm.perfectMoneyEmail,
        accountNumber: this.TransferForm.perfectMoneyAccount,
        importantNotes: this.TransferForm.importantNotes,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
