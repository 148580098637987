<template>
  <!-- Skrill -->
  <div id="skrillForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/skrill_icon.png" alt="skrill_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list two-fields clearfix">
          <li>
            <el-form-item :label="$t('withdraw.skrill.field.email')" prop="skrillEmial_skrill">
              <el-input
                v-model="TransferForm.skrillEmial_skrill"
                id="skrillEmial_skrill"
                type="email"
                auto-complete="new-password"
                data-testid="email"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.notes_skrill"
                id="importantNotes"
                auto-complete="new-password"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
      <FeeTip
        :withdrawalType="withdrawalType"
        :currency="currency"
        :withdrawalAmount="amount"
        :styleType="2"
        :mt4Account="accountNumber"
      />
    </div>
  </div>
</template>

<script>
import { validateEmail } from '@/util/validation';
import FeeTip from '@/components/withdraw/FeeTip';

export default {
  name: 'SkrillWithdraw',
  components: { FeeTip },
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults', 'chooseWithdrawalType', 'currency'],
  data() {
    return {
      TransferForm: {
        skrillEmial_skrill: '',
        notes_skrill: ''
      },
      TransferFormRules: {
        skrillEmial_skrill: [
          {
            required: true,
            message: this.$t('withdraw.skrill.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        skrillEmail: this.TransferForm.skrillEmial_skrill,
        importantNotes: this.TransferForm.notes_skrill,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  height: 48px !important;
}

@media (min-width: 1200px) {
  .logo {
    height: 36px !important;
  }
}
</style>
