<template>
  <!-- PhilipinessForm -->
  <div id="PhilipinessForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/vietna_icon.png" alt="vietna_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="selectedCardID"
              data-testid="selectedCardID"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form-list clearfix">
            <li>
              <SelectForm
                v-model="TransferForm.bankName"
                class="plain-select"
                :label="$t('common.field.bankName')"
                name="bankName"
                data-testid="bankName"
                popperClass="withdraw-select"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="(item, index) in bankList"
                  :key="index"
                  :value="item"
                  :label="index"
                  :data-testid="item"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress">
                <el-input
                  v-model="TransferForm.bankAddress"
                  id="bankAddress"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankAddress"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="bankBeneficiaryName">
                <el-input
                  v-model="TransferForm.bankBeneficiaryName"
                  id="bankBeneficiaryName"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankBeneficiaryName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
                <el-input
                  v-model.trim="TransferForm.accountNumber"
                  id="accountNumber"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="accountNumber"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                <el-input
                  v-model="TransferForm.notes"
                  id="notes"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="importantNotes"
                />
              </el-form-item>
            </li>
          </ul>
          <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="checkbox">
              {{ $t('withdraw.default.remember') }}
            </el-checkbox>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import SelectForm from '@/components/form/Select';

export default {
  name: 'PhilipinessWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    bankList: Object,
    followerResults: Array
  },
  mixins: [withdrawlMixin, bankTransferMixin],
  components: { SelectForm },
  data() {
    return {
      TransferForm: {
        bankName: '',
        bankAddress: '',
        bankBeneficiaryName: '',
        accountNumber: '',
        notes: '',
        userPaymentInfoId: null
      },
      phpCountryCode: 4131,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankBeneficiaryName: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      const selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);

      this.TransferForm = {
        selectedCardID: value,
        bankName: selectedCardInfo.bankName || '',
        bankBeneficiaryName: selectedCardInfo.beneficiaryName || '',
        bankAddress: selectedCardInfo.bankAddress || '',
        accountNumber: selectedCardInfo.accountNumber || '',
        notes: selectedCardInfo.importantNotes || '',
        userPaymentInfoId: selectedCardInfo && selectedCardInfo.id !== -1 ? selectedCardInfo.id : null
      };
      this.isdisabled = selectedCardInfo && selectedCardInfo.id !== -1 ? true : false;
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName'(value) {
      this.TransferForm.bankBeneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName,
        bankAddress: this.TransferForm.bankAddress,
        beneficiaryName: this.TransferForm.bankBeneficiaryName,
        accountNumber: this.TransferForm.accountNumber,
        importantNotes: this.TransferForm.notes,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
@import '@/assets/css/components/common/SelectLine';
</style>
